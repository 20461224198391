import { useContext, useEffect, useState } from 'react';
import { ShareContext } from '../../context/ShareContext';
import Tooltip from '../ui/Tooltip';

const ShareEggCard = ({ index, data }) => {
  const { methodData, setMethodData } = useContext(ShareContext);
  const currentYear = new Date().getFullYear();
  const currentRetrievalDate = new Date(data?.date);
  const selectedYear = currentRetrievalDate.getFullYear();
  const selectedMonth = currentRetrievalDate.getMonth() + 1;
  const yearNumbers = Array.from({ length: 101 }, (_, i) => currentYear - i);
  const monthNumbers = Array.from({ length: 12 }, (_, i) => i + 1);

  const eggNumbers = Array.from({ length: 39 }, (_, i) => i);
  const eggArray = ['N/A', ...eggNumbers, '40+'];

  const handleData = (fieldName, value) => {
    // Make a copy of the parent's retrievals array
    const updatedRetrievals = [...methodData.retrievals];

    // Modify the specific entry based on the current index
    updatedRetrievals[index] = {
      ...updatedRetrievals[index], // Retain the current values
      [fieldName]: value // Update the specified field with the new value
    };

    setMethodData((prevState) => ({
      ...prevState,
      retrievals: updatedRetrievals
    }));
  };

  const handleDateData = (fieldName, value) => {
    // Make a copy of the current data for this card
    const updatedData = { ...data };

    // Adjust the desired field of the specified item
    const currentRetrievalDate = new Date(updatedData.date);
    if (fieldName === 'year') {
      currentRetrievalDate.setFullYear(value);
    } else {
      // Subtract 1 because months in JavaScript are 0-indexed
      currentRetrievalDate.setMonth(value - 1);
    }
    updatedData.date = currentRetrievalDate;

    // Make a copy of the parent's retrievals array
    const updatedRetrievals = [...methodData.retrievals];

    // Update the specific entry based on the current index
    updatedRetrievals[index] = updatedData;
    // Pass the modified retrievals array up to the parent using handleData or directly using setMethodData
    setMethodData((prevState) => ({
      ...prevState,
      retrievals: updatedRetrievals
    }));
  };

  const handleRemoveCard = () => {
    const updatedRetrievals = [...methodData.retrievals];
    updatedRetrievals.splice(index, 1);
    setMethodData((prevState) => ({
      ...prevState,
      retrievals: updatedRetrievals,
      retrievalCount: prevState.retrievalCount - 1
    }));
  };

  return (
    <div className="relative">
      <div className="bg-card-green flex flex-col px-3 py-2 items-center justify-center my-2">
        <button
          onClick={handleRemoveCard}
          className="z-10 absolute top-0 right-0 font-bold text-[12px] text-black rounded-full p-3"
          aria-label="Remove Card"
        >
          Remove
        </button>

        <div className="relative">
          <Tooltip
            className={'absolute top-1 left-0 ml-0'}
            textClass={'w-[180px] xs:w-[250px] sm:w-[350px]'}
            text={
              "Please share as much as you remember from your egg retrieval. The more information you can share, the more helpful people will find it. If you're not sure of your outcome or it's best to leave unanswered."
            }
          />
          <div className="flex flex-row items-end">
            <div className="mt-6 font-bold text-[12px] font-serif mr-2">{`ER${index + 1}`}</div>
            <div className="grid grid-flow-col gap-2 items-center">
              <div className="flex flex-col">
                <div className="font-bold font-serif text-[10px] mb-1">Year</div>
                <select
                  id="method"
                  name="method"
                  onChange={(e) => handleDateData('year', e.target.value)}
                  value={selectedYear}
                  // onChange={(e) => setMethod(e.target.value)}
                  className="w-[70px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  {yearNumbers.map((year) => (
                    <option key={year} className="font-bold text-[12px] font-serif">
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col w-[50px]">
                <div className="font-bold font-serif text-[10px] mb-1">Month</div>
                <select
                  id="method"
                  name="method"
                  onChange={(e) => handleDateData('month', e.target.value)}
                  value={selectedMonth}
                  // onChange={(e) => setMethod(e.target.value)}
                  className="mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  {monthNumbers.map((month) => (
                    <option key={month} className="font-bold text-[12px] font-serif">
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <div className="font-bold font-serif text-[10px] mb-1">Donor?</div>
                <select
                  id="method"
                  name="method"
                  value={data?.donorType}
                  onChange={(e) => handleData('donorType', e.target.value)}
                  className="w-[140px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  <option value="none" className="font-bold text-[12px] font-serif">
                    None
                  </option>
                  <option value="eggs" className="font-bold text-[12px] font-serif">
                    Donor Eggs
                  </option>
                  <option value="sperm" className="font-bold text-[12px] font-serif">
                    Donor Sperm
                  </option>
                  <option value="embryos" className="font-bold text-[12px] font-serif">
                    Donor Embryos
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2">
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Clinic Name</div>
              <input
                onChange={(e) => handleData('clinic', e.target.value)}
                value={data?.clinic}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Doctor Name</div>
              <input
                onChange={(e) => handleData('doctor', e.target.value)}
                value={data?.doctor}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 mt-2 items-end justify-center gap-2">
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Eggs Retrieved</div>
              <select
                id="method"
                name="method"
                value={data?.eggsRetrieved}
                onChange={(e) => handleData('eggsRetrieved', e.target.value)}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Mature Eggs</div>
              <select
                id="method"
                name="method"
                value={data?.eggsMature}
                onChange={(e) => handleData('eggsMature', e.target.value)}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Fertilized Eggs</div>
              <select
                id="method"
                name="method"
                value={data?.eggsFertilized}
                onChange={(e) => handleData('eggsFertilized', e.target.value)}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Day 3 Embryos</div>
              <select
                id="method"
                name="method"
                value={data?.day3Embryos}
                onChange={(e) => handleData('day3Embryos', e.target.value)}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Day 5 Embryos (Blastocyst)</div>
              <select
                id="method"
                name="method"
                value={data?.blastocysts}
                onChange={(e) => handleData('blastocysts', e.target.value)}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 pt-4">
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px] mb-1">Fertilization</div>
              <select
                id="method"
                name="method"
                value={data?.fertilization}
                onChange={(e) => handleData('fertilization', e.target.value)}
                className="w-[140px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                <option value="no" className="font-bold text-[12px] font-serif">
                  Don't Know
                </option>
                <option value="icsi" className="font-bold text-[12px] font-serif">
                  ICSI
                </option>
                <option value="ivf" className="font-bold text-[12px] font-serif">
                  IVF
                </option>
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px] mb-1">Did you do PGT?</div>
              <select
                id="method"
                name="method"
                onChange={(e) => handleData('pgt', e.target.value)}
                value={data.pgt}
                className="w-[140px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                <option value="na" className="font-bold text-[12px] font-serif">
                  Not Applicable
                </option>
                <option value="pgta" className="font-bold text-[12px] font-serif">
                  PGT-A (PGS)
                </option>
                <option value="pgtm" className="font-bold text-[12px] font-serif">
                  PGT-M (PGD)
                </option>
              </select>
            </div>

            <div className="flex flex-col mr-5 items-center">
              <div className="font-bold font-serif text-[7px] md:text-[10px]">
                Genertically “Normal” Embyros
              </div>
              <select
                id="method"
                name="method"
                onChange={(e) => handleData('normalEmbryos', e.target.value)}
                value={data.normalEmbryos}
                className="w-[50px] mt-1 block w-full py-2 pl-2 border border-black bg-card-green rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
              >
                {eggArray.map((month) => (
                  <option key={month} value={month} className="font-bold text-[12px] font-serif">
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col py-2">
            <div className="font-bold font-serif text-[10px]">
              If you know the exact stimulation protocol (name, dosage, and days) please share here.
            </div>
            <textarea
              className="w-full mt-2 bg-card-green rounded-lg p-2 border-[1px] border-black focus:outline-none"
              rows={3}
              onChange={(e) => handleData('stimulationProtocol', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareEggCard;
