import React from 'react';

const OnboardingLoginButton = ({ children, onClick }) => {
  const buttonStyle = `font-sans rounded-md outline-none font-semibold text-2xl px-4 py-3 w-full text-white bg-light-purple mt-2`;

  return (
    <button onClick={onClick} className={buttonStyle}>
      {children}
    </button>
  );
};

export default OnboardingLoginButton;
