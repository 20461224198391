import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../context/SessionContext';
import { useNavigate } from 'react-router-dom';
import { ShareContext } from '../context/ShareContext';
import axios from 'axios';
import QuickShareStart from '../components/quickshare/QuickShareStart';
import QuickShareTags from '../components/quickshare/QuickShareTags';
import QuickShareMethods from '../components/quickshare/QuickShareMethods';

const QuickSharePage = () => {
  const navigate = useNavigate();
  const { user, isLoading } = useContext(SessionContext);
  const { age, race, tags, method, title, methodData, resetMethodData, resetTags } =
    useContext(ShareContext);
  const [handleLocalLoginError, setHandleLocalLoginError] = useState(false);
  const [step, setStep] = useState(-1);

  useEffect(() => {
    if (!isLoading && !user) {
      console.error('Invalid Dashboard');
      setStep(0);
    }
    if (user) {
      setStep(2);
    }
  }, [user, isLoading]);

  const handleSubmit = async () => {
    const selectedTags = Object.entries(tags)
      .filter(([key, tag]) => tag.value === true)
      .map(([key, tag]) => ({ identifier: key, factor: tag.factor, name: tag.name }));
    const body = {
      author: null,
      title: title,
      method: method,
      tags: selectedTags,
      age: age,
      race: user?.userData?.race ? user.userData?.race : race
    };
    if (method === 'timed') {
      body.methodData = {
        method: method,
        triedPregnant: methodData.triedPregnant,
        successfulCase: methodData.successfulCase,
        medicated: methodData.medicated,
        medicationTaken: methodData.medicationTaken,
        whyWorked: methodData.whyWorked,
        alternatives: methodData.alternatives,
        cycles: methodData.cycles,
        advice: methodData?.advice
      };
      if (methodData.medicationOther.selected) {
        body.methodData.medicationOther = methodData.medicationOther.value;
        body.methodData.medicationTaken.push('Other');
      }
    } else if (method === 'iui') {
      body.methodData = {
        method: method,
        triedPregnant: methodData.triedPregnant,
        successfulCase: methodData.successfulCase,
        medicated: methodData.medicated,
        medicationTaken: methodData.medicationTaken,
        whyWorked: methodData.whyWorked,
        alternatives: methodData.alternatives,
        cycles: methodData.cycles,
        pricing: methodData.pricing,
        donorSperm: methodData.donorSperm,
        advice: methodData?.advice
      };
      if (methodData.medicationOther.selected) {
        body.methodData.medicationOther = methodData.medicationOther.value;
        body.methodData.medicationTaken.push('Other');
      }
    } else if (method === 'ivf') {
      body.methodData = {
        method: method,
        triedPregnant: methodData.triedPregnant,
        successfulCase: methodData.successfulCase,
        whyWorked: methodData.whyWorked,
        alternatives: methodData.alternatives,
        retrievalCount: methodData.retrievalCount,
        retrievals: methodData.retrievals,
        transferCount: methodData.transferCount,
        transfers: methodData.transfers,
        pricing: methodData.pricing,
        advice: methodData?.advice
      };
    } else if (method === 'freezing') {
      body.methodData = {
        method: method,
        successfulCase: methodData.successfulCase,
        whyWorked: methodData.whyWorked,
        alternatives: methodData.alternatives,
        retrievalCount: methodData.retrievalCount,
        retrievals: methodData.retrievals,
        pricing: methodData.pricing,
        advice: methodData?.advice
      };
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/post`, body, {
        withCredentials: true
      });
      if (data) {
        resetMethodData();
        resetTags();
        window.history.pushState({}, '', '/stories');
        navigate(`/post/${data._id}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Handle onboard routing
  const shareRouter = () => {
    if (step === 0) {
      return <QuickShareStart setStep={setStep} />;
    } else if (step === 1) {
      return <QuickShareTags setStep={setStep} />;
    } else if (step === 2) {
      return <QuickShareMethods setStep={setStep} handleSubmit={handleSubmit} />;
    }
  };

  return <div className="bg-white w-full h-full px-5 overflow-auto">{shareRouter()}</div>;
};

export default QuickSharePage;
