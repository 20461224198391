import { useContext, useEffect, useState } from 'react';
import { ShareContext } from '../../context/ShareContext';
import CommunitySubheader from '../community/CommunitySubheader';
import ShareTextArea from './ShareTextArea';
import ShareQuestion from './ShareQuestion';
import CommunityIntegerInput from '../community/CommunityIntegerInput';
import CommunityCheckbox from '../community/CommunityCheckbox';
import Tooltip from '../ui/Tooltip';

const ShareIUI = ({}) => {
  const { methodData, setMethodData } = useContext(ShareContext);

  const medications = [
    'Metformin',
    'Clomid',
    'Letrozole / Fermara',
    'Menopur',
    'Estradiol',
    'Gonal-F',
    'Trigger: Lupron',
    'Trigger: HCG',
    'Monitored Cycle',
    'Medicated TI was not successful'
  ];

  const [medication, setMedication] = useState([]);
  const [medicationOther, setMedicationOther] = useState({ selected: false, value: '' });

  const handleData = (data, value) => {
    setMethodData((prevState) => ({
      ...prevState,
      [data]: value
    }));
  };

  const handleMedChange = (med, isChecked) => {
    if (isChecked) {
      // add medication to the list
      setMedication([...medication, med]);
    } else {
      // remove medication from the list
      setMedication(medication.filter((item) => item !== med));
    }
  };

  const handleIntegerInput = (data, event) => {
    const newValue = event.target.value;

    if (!event.target.validity.valid) {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: 0
      }));
    } else {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: newValue
      }));
    }
  };

  useEffect(() => {
    // whenever medicationOther is updated update context
    setMethodData((prevState) => ({
      ...prevState,
      medicationOther: { selected: medicationOther.selected, value: medicationOther.value }
    }));
  }, [medicationOther]);

  useEffect(() => {
    // whenever medication array is updated update context
    setMethodData((prevState) => ({
      ...prevState,
      medicationTaken: medication
    }));
  }, [medication]);
  return (
    <div className="">
      <div>
        <CommunitySubheader className={'mt-5 mb-4 font-bold'} title={'IUI'} />
        <ShareQuestion
          text={`How long have you been trying to conceive? And what have you tried before this?`}
          className={'mb-3'}
        />
        <ShareTextArea
          text={methodData.triedPregnant}
          onChangeText={(e) => handleData('triedPregnant', e.target.value)}
        />
        <div className="flex flex-row my-3">
          <div className="flex flex-col">
            <ShareQuestion text={'How many cycles have you tried IUI'} />
            <ShareQuestion text={'before?'} />
          </div>
          <div className="ml-auto mr-10">
            <CommunityIntegerInput
              className={'w-[45px]'}
              value={methodData.cycles}
              data={'cycles'}
              onChange={handleIntegerInput}
            />
          </div>
        </div>
        <ShareQuestion text={`What did you do on your successful IUI?`} className={'mb-2'} />
        <ShareTextArea
          text={methodData.successfulCase}
          onChangeText={(e) => handleData('successfulCase', e.target.value)}
        />

        <div className="flex flex-row mt-2">
          <div className="flex flex-col mr-20">
            <ShareQuestion text="Medicated?" />
            <select
              id="medicated"
              name="medicated"
              value={methodData.medicated}
              onChange={(e) => handleData('medicated', e.target.value)}
              className="w-[160px] mt-1 block w-full py-2 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
            >
              <option value="yes" className="font-bold text-[12px] font-serif">
                Yes
              </option>
              <option value="no" className="font-bold text-[12px] font-serif">
                No
              </option>
              <option value="forget" className="font-bold text-[12px] font-serif">
                Don't Remember
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <ShareQuestion text="Donor Sperm?" />
            <select
              id="donorSperm"
              name="donorSperm"
              value={methodData.donorSperm}
              onChange={(e) => handleData('donorSperm', e.target.value)}
              className="w-[60px] mt-1 block w-full py-2 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
            >
              <option value="no" className="font-bold text-[12px] font-serif">
                No
              </option>
              <option value="yes" className="font-bold text-[12px] font-serif">
                Yes
              </option>
            </select>
          </div>
        </div>
        {methodData.medicated === 'yes' ? (
          <div className="mt-1">
            <ShareQuestion text="Medication Taken" className={'mt-2'} />
            {medications.map((med) => (
              <CommunityCheckbox
                key={med}
                label={med}
                isChecked={medication.includes(med)}
                handleOnChange={(isChecked) => handleMedChange(med, !isChecked)}
              />
            ))}
            <div className="flex flex-row items-center">
              <CommunityCheckbox
                label={'Other: '}
                isChecked={medicationOther.selected}
                handleOnChange={(isChecked) =>
                  setMedicationOther({ selected: !isChecked, value: medicationOther.value })
                }
              />
              <input
                type="text"
                className="border-b border-black bg-transparent outline-none focus:outline-none px-2 mt-2 font-serif text-[12px]"
                value={medicationOther.value}
                onChange={(e) =>
                  setMedicationOther({
                    selected: medicationOther.selected,
                    value: e.target.value
                  })
                }
              />
            </div>
          </div>
        ) : null}

        <div className="flex flex-row">
          <ShareQuestion text={`Why do you think this IUI worked?`} className={'mb-2 mt-4'} />
          <Tooltip
            className={'ml-1'}
            textClass={'w-[150px] xs:w-[250px] sm:w-[350px]'}
            text={
              "We can't fully understand why things sometime work and they sometimes don't. But if you have any theories or hunches you'd like to share people may find your takes helpful. Feel free to omit if you don't have anything to add."
            }
          />
        </div>
        <ShareTextArea
          text={methodData.whyWorked}
          onChangeText={(e) => handleData('whyWorked', e.target.value)}
        />
        <ShareQuestion
          text={`If you or your partner used any alternative interventions 
          (supplements, acupuncture, diet changes, etc) 
          please share here.`}
          className={'mb-2'}
        />
        <ShareTextArea
          text={methodData.alternatives}
          onChangeText={(e) => handleData('alternatives', e.target.value)}
        />
        <ShareQuestion text={`Can you share how much this IUI cost you?`} className={'mb-2'} />
        <ShareTextArea
          text={methodData.pricing}
          onChangeText={(e) => handleData('pricing', e.target.value)}
        />
        <ShareQuestion
          text={`Would you like to share anything else with people going through the fertility journey?`}
          className={'mb-2'}
        />
        <ShareTextArea
          text={methodData.advice}
          onChangeText={(e) => handleData('advice', e.target.value)}
        />
      </div>
    </div>
  );
};

export default ShareIUI;
