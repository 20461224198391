import React from 'react';

const CommunityButton = ({ children, onClick, disabled }) => {
  const buttonStyle = `font-serif rounded-md outline-none font-semibold text-[20px] w-full text-white ${
    disabled ? 'bg-purple bg-opacity-60 cursor-not-allowed' : 'bg-purple'
  }`;

  return (
    <button
      style={{ height: '40px' }}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
    >
      {children}
    </button>
  );
};

export default CommunityButton;
