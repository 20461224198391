import React from 'react';

const ResourceDetailsPage = ({ title, setSelectedResource }) => {
  const details = [
    {
      header: '1. Poor estrogen detoxers/too much estrogen:',
      texts: [
        `a.  Design for Health Calcium d-glucarate - helps
  detoxification process, rids toxins from body, eliminates excess steroid hormones`,
        `b. DFH DIM-Evail - diindoylmethane supports healthy estrogen metabolism. this product combined w/ MCT
  oil, improves absorption`,
        `c. Orthomolecular CDG Estrodim - combines CaDglucarate, indol 3 carbinol and DIM`
      ]
    },
    {
      header:
        '2. Combo hormone balancing (supports healthy cycle/ ratio of estrogen to progesterone)',
      texts: [
        `a. DFH
      FemGuard + balance - herbal hormone balancing - vitex (supports health progesterone levels),
      Calcium d-glucarate, DIM, chrysin, and antioxidants (EGCG, resveratrol), b vitamins, mag,
      calcium`,
        `b. Orthomolecular balance -helps promote healthy cycle and balance estrogen/progesterone
      - chaste tree berry, licorice root`
      ]
    },
    {
      header: '3. Libido support',
      texts: [
        `a. DFH LibidoStim-F - consult healthcare
      practitioner - mostly for those with very low testosterone, contains combo of herbals to
      promote sexual desire and pleasure`,
        `b. Tribulus - MediHerb Standard Process Testoplex`,
        `c.  - Xymogen
      shilajit and eurycoma longifolia - supports maintaining testosterone levels both men and women
      prenatal DFH prenatal pro - nutrients, methylated folate, chelated iron, chelated zinc, iodine
      (supports healthy brain development Orthomolecular prenatal complete with DHA - iodione,
      chelated iron, chelated zinc and DHA/EPA Ovaterra advanced prenatal - with choline, iodine,
      iron (chelated) Probiotics - good for XXX Orthomolecular orthobiotic women’s - healthy vaginal
      and gen hormones - consult healthcare practitioner pregnenolone progesterone Ovulation support
      berberine - for PCOS/diabetes. I like Metagenics candibactin AR or integrative therapeutics
      berberine supplement. improves insulin resistance in theca cells (ovarian cells surrounding a
      follicle) - can improve ovulation rate per cycle and thus fertility myo-inositol and
      d-chiro-inositol - more for PCOS CoQ10/ Ubiquinol - antioxidant, decreases as you age, may
      improve egg qualtiy`
      ]
    }
  ];

  return (
    <div className="pb-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex-shrink-0">
          <button
            className="font-serif font-bold text-2xl text-center leading-7 ml-2 mr-4"
            onClick={() => setSelectedResource(null)}
          >
            {'<'}
          </button>
        </div>
        <div className="w-full text-left">
          <h2 className="text-0E0E0E font-serif text-2xl font-bold leading-7">{title}</h2>
        </div>
        <div className="flex-shrink-0"></div>
      </div>

      {details.map((detail, i) => {
        return (
          <div>
            <div className="mb-4">{detail.header}</div>
            {detail.texts.map((text) => {
              return <div className="mb-2">{text} </div>;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ResourceDetailsPage;
