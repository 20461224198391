import React from 'react';
import PostTag from '../PostTag';
import PostMethodTag from '../PostMethodTag';
import PostAgeTag from '../PostAgeTag';
import { Link } from 'react-router-dom';

const PostSearchCard = ({ post, className }) => {
  return (
    <Link to={`/post/${post._id}`}>
      <div
        className={`flex flex-col justify-center border-b border-black border-opacity-30 -mx-5 px-5 mb-4 ${className}`}
      >
        <div className={`text-[16px] font-serif font-bold leading-[20px] text-black mb-2`}>
          {post.title}
        </div>
        <div className="flex flex-row flex-wrap gap-2 mb-5">
          <PostMethodTag method={post.method} />
          {post?.age ? <PostAgeTag age={post.age} /> : null}
          {post?.tags?.map((tag, i) => (
            <PostTag key={i} tag={tag} />
          ))}
        </div>
      </div>
    </Link>
  );
};

export default PostSearchCard;
