import React from 'react';

const AnswerBirthday = ({ handleStep, answer, step }) => {
  // get the current date
  const currentDate = new Date();

  // format to yyyy-mm-dd
  const formattedDate = currentDate.toISOString().substring(0, 10);

  // set initial value to today's date
  const [value, setValue] = React.useState(formattedDate);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        style={{ height: '60px' }}
        className="flex items-center rounded-md border-2 border-black px-4"
      >
        <input
          type="date"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          className={`bg-transparent outline-none flex-grow text-center text-xl`}
        />
      </div>
      <button
        onClick={() => handleStep(step, answer, value)}
        className="bg-purple p-2 text-white rounded-md mt-4"
      >
        Next
      </button>
    </>
  );
};

export default AnswerBirthday;
