export const transferTypeString = (id) => {
  switch (id) {
    case 'fresh':
      return 'Fresh';
    case 'frozen':
      return 'Frozen';
    case 'no':
      return '';
    default:
      return '';
  }
};
