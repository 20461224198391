import React from 'react';

const PostMethodTag = ({ method }) => {
  const methodNames = {
    timed: 'Timed Intercourse',
    ivf: 'IVF',
    iui: 'IUI',
    freezing: 'Egg/Embryo Freezing'
  };

  return <div className={'bg-method-tag p-1 rounded'}>{methodNames[method]}</div>;
};

export default PostMethodTag;
