import React from 'react';

const OnboardingButton = ({ children, onClick, disabled }) => {
  const buttonStyle = `font-sans rounded-md outline-none font-semibold text-2xl px-4 py-3 w-full text-white ${
    disabled ? 'bg-purple bg-opacity-60 cursor-not-allowed' : 'bg-purple'
  }`;

  return (
    <button disabled={disabled} onClick={onClick} className={buttonStyle}>
      {children}
    </button>
  );
};

export default OnboardingButton;
