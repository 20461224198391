import React, { useState } from 'react';
import OnboardingLoginButton from '../components/onboarding/OnboardingLoginButton';
import axios from 'axios';
import DashboardLayout from './DashboardLayout';

const SettingsPage = () => {
  const handleLogout = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        withCredentials: true
      });
      if (data) {
        // Change the current page to /onboarding
        window.location.href = '/onboarding';

        // Force a reload of the page
        window.location.reload(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout currentPageIndex={4}>
      <div className="pb-5">
        <OnboardingLoginButton onClick={handleLogout}>Log Out</OnboardingLoginButton>
      </div>
    </DashboardLayout>
  );
};

export default SettingsPage;
