import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../context/SessionContext';
import CommunityButton from '../community/CommunityButton';
import CommunitySubheader from '../community/CommunitySubheader';
import ShareTimed from './ShareTimed';
import ShareIUI from './ShareIUI';
import ShareIVF from './ShareIVF';
import ShareFreezing from './ShareFreezing';
import { useNavigate } from 'react-router-dom';
import { ShareContext } from '../../context/ShareContext';
import Tooltip from '../ui/Tooltip';

const ShareMethods = ({ setStep, handleSubmit }) => {
  const navigate = useNavigate();
  const { title, setTitle, method, setMethod, resetMethodData } = useContext(ShareContext);
  const [invalidTitle, setInvalidTitle] = useState(false);

  const validateData = () => {
    let hasError = false;
    if (title?.trim() === '') {
      setInvalidTitle(true);
      hasError = true;
    } else setInvalidTitle(false);

    if (hasError) return;
    handleSubmit();
  };

  const handleSetMethod = (method) => {
    resetMethodData();
    setMethod(method);
  };

  const displayMethod = () => {
    if (method === 'ivf') return <ShareIVF />;
    else if (method === 'freezing') return <ShareFreezing />;
    else if (method === 'iui') return <ShareIUI />;
    else if (method === 'timed') return <ShareTimed />;
  };

  return (
    <div className="bg-white w-full h-full flex flex-col pb-2">
      <div className="flex flex-row justify-start">
        <button className="focus:outline-none mb-7 mr-2" onClick={() => setStep(2)}>
          <img
            style={{ width: '30px', height: '30px' }}
            src={'/assets/arrow_back.svg'}
            alt={'arrow'}
          />
        </button>
        <p className="font-serif font-bold text-2xl text-left leading-8 mb-3 mt-4">
          Share your <br /> Successful Fertility Story
        </p>
      </div>
      <div>
        <div className="text-[12px] font-serif font-bold leading-[16px] mb-2">Story Title</div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-[80%] border-[1px] p-1 border-black rounded-md focus:outline-none"
          placeholder="Two years, four egg retrievals later, my fertility journey"
        />
        {invalidTitle && (
          <div className="text-red-500 mt-2 text-[12px] font-serif font-bold leading-[16px]">
            Title is required
          </div>
        )}
        <div className="flex flex-row">
          <CommunitySubheader
            className={'mt-5 mb-4 font-bold text-dark-green'}
            title={'Your Successful Method'}
          />
          <Tooltip
            className={'ml-1'}
            textClass={'w-[180px] xs:w-[250px] sm:w-[350px]'}
            text={`If you're sharing an egg/ embryo freezing story, please select "egg/ embryo freezing." If you're sharing a successful pregnancy story, please select the method with which you got to pregnancy.`}
          />
        </div>

        <select
          id="method"
          name="method"
          value={method}
          onChange={(e) => handleSetMethod(e.target.value)}
          className="w-[255px] mt-1 block w-full py-2 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
        >
          <option value="ivf" className="font-bold text-[12px] font-serif">
            IVF (In Vitro Fertilization)
          </option>
          <option value="freezing" className="font-bold text-[12px] font-serif">
            Egg/ Embryo Freezing
          </option>
          <option value="iui" className="font-bold text-[12px] font-serif">
            IUI (Intrauterine Insemination)
          </option>
          <option value="timed" className="font-bold text-[12px] font-serif">
            Timed Intercourse
          </option>
        </select>
      </div>
      {displayMethod()}
      <div className="flex flex-col justify-center mt-10">
        <CommunityButton onClick={() => validateData()}>Submit</CommunityButton>
      </div>
    </div>
  );
};

export default ShareMethods;
