import React, { useState, useRef } from 'react';

const Tooltip = ({ text, className, position = 'right', textClass }) => {
  const [isHovered, setIsHovered] = useState(false);
  const btnRef = useRef(null);
  const [coords, setCoords] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    const rect = btnRef.current.getBoundingClientRect();

    let newLeft;
    if (position === 'right') {
      newLeft = rect.right + window.scrollX + 5; // 5px gap from the button
    } else {
      newLeft = rect.left + window.scrollX - 5 - rect.width; // 5px gap from the button
    }

    setCoords({
      top: rect.top + window.scrollY,
      left: newLeft
    });
    setIsHovered(true);
  };

  return (
    <button
      className={`${className}`}
      ref={btnRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <span
          className={`absolute bg-gray-700 text-white text-xs rounded py-1 px-2 text-left text-serif
           ${textClass}`}
        >
          {text}
        </span>
      )}
      <img style={{ width: '11px', height: '11px' }} src={'/assets/info.svg'} alt={'info'} />
    </button>
  );
};

export default Tooltip;
