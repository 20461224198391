import React from 'react';
import ChecklistCard from './ChecklistCard';

const Checklist = ({ title, contentList, handleCheckBox, checkedBoxes }) => {
  return (
    <div>
      <h2 className="font-serif font-bold text-font-grey text-sm mb-1">{title}</h2>
      {contentList.map((card, i) => (
        <ChecklistCard
          key={i}
          checkId={card.id}
          checked={card.checked}
          checkedBoxes={checkedBoxes}
          content={card.content}
          handleCheckBox={handleCheckBox}
        />
      ))}
    </div>
  );
};

export default Checklist;
