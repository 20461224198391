import React from 'react';
import CommunityCheckbox from '../community/CommunityCheckbox';
import { useContext, useEffect, useState } from 'react';
import { ShareContext } from '../../context/ShareContext';
import Tooltip from '../ui/Tooltip';

const ShareTransferCard = ({ index, data }) => {
  const { methodData, setMethodData } = useContext(ShareContext);
  const currentYear = new Date().getFullYear();
  const currentRetrievalDate = new Date(data?.date);
  const selectedYear = currentRetrievalDate.getFullYear();
  const selectedMonth = currentRetrievalDate.getMonth() + 1;
  const yearNumbers = Array.from({ length: 101 }, (_, i) => currentYear - i);
  const monthNumbers = Array.from({ length: 12 }, (_, i) => i + 1);

  const handleData = (fieldName, value) => {
    // Make a copy of the parent's retrievals array
    const updatedTransfers = [...methodData.transfers];

    // Modify the specific entry based on the current index
    updatedTransfers[index] = {
      ...updatedTransfers[index], // Retain the current values
      [fieldName]: value // Update the specified field with the new value
    };

    setMethodData((prevState) => ({
      ...prevState,
      transfers: updatedTransfers
    }));
  };

  const handleDateData = (fieldName, value) => {
    // Make a copy of the current data for this card
    const updatedData = { ...data };

    // Adjust the desired field of the specified item
    const currentTransferDate = new Date(updatedData.date);
    if (fieldName === 'year') {
      currentTransferDate.setFullYear(value);
    } else {
      // Subtract 1 because months in JavaScript are 0-indexed
      currentTransferDate.setMonth(value - 1);
    }
    updatedData.date = currentTransferDate;

    // Make a copy of the parent's retrievals array
    const updatedTransfers = [...methodData.retrievals];

    // Update the specific entry based on the current index
    updatedTransfers[index] = updatedData;
    // Pass the modified retrievals array up to the parent using handleData or directly using setMethodData

    setMethodData((prevState) => ({
      ...prevState,
      transfers: updatedTransfers
    }));
  };

  const handleRemoveCard = () => {
    const updatedTransfers = [...methodData.transfers];
    updatedTransfers.splice(index, 1);
    setMethodData((prevState) => ({
      ...prevState,
      transfers: updatedTransfers,
      transferCount: prevState.transferCount - 1
    }));
  };

  return (
    <div className="relative">
      <div className="bg-card-purple flex flex-col px-3 py-2 my-2 items-center justify-center">
        <button
          onClick={handleRemoveCard}
          className="z-10 absolute top-0 right-0 font-bold text-[12px] text-black rounded-full p-3"
          aria-label="Remove Card"
        >
          Remove
        </button>
        <div className="relative">
          <Tooltip
            className={'absolute top-1 left-0 ml-0'}
            textClass={'w-[180px] xs:w-[250px] sm:w-[350px]'}
            text={
              "Please share as much as you remember from your embryo transfer. The more information you can share, the more helpful people will find it. If you're not sure of your outcome or it's best to leave unanswered."
            }
          />

          <div className="flex flex-row items-end">
            <div className="mt-6 font-bold text-[12px] font-serif mr-2">{`Transfer${
              index + 1
            }`}</div>
            <div className="grid grid-flow-col gap-2 items-end">
              <div className="flex flex-col">
                <div className="font-bold font-serif text-[10px] mb-1">Year</div>
                <select
                  id="method"
                  name="method"
                  onChange={(e) => handleDateData('year', e.target.value)}
                  value={selectedYear}
                  // onChange={(e) => setMethod(e.target.value)}
                  className="w-[70px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  {yearNumbers.map((year) => (
                    <option key={year} value={year} className="font-bold text-[12px] font-serif">
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col mr-2">
                <div className="font-bold font-serif text-[10px] mb-1">Month</div>
                <select
                  id="method"
                  name="method"
                  onChange={(e) => handleDateData('month', e.target.value)}
                  value={selectedMonth}
                  // onChange={(e) => setMethod(e.target.value)}
                  className="w-[50px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  {monthNumbers.map((month) => (
                    <option key={month} value={month} className="font-bold text-[12px] font-serif">
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <div className="font-bold font-serif text-[10px] mb-1"> </div>
                <select
                  id="method"
                  name="method"
                  onChange={(e) => handleData('transferType', e.target.value)}
                  value={data?.transferType}
                  // onChange={(e) => setMethod(e.target.value)}
                  className="w-[110px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
                >
                  <option value="fresh" className="font-bold text-[12px] font-serif">
                    Fresh
                  </option>
                  <option value="frozen" className="font-bold text-[12px] font-serif">
                    Frozen
                  </option>
                  <option value="no" className="font-bold text-[12px] font-serif">
                    Don't Know
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2 gap-4">
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Clinic Name</div>
              <input
                onChange={(e) => handleData('clinic', e.target.value)}
                value={data?.clinic}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-bold font-serif text-[10px]">Doctor Name</div>
              <input
                onChange={(e) => handleData('doctor', e.target.value)}
                value={data?.doctor}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 mt-2">
            <div className="flex flex-col">
              <div className="hidden sm:block mr-2 font-bold text-[12px] font-serif">
                Number of Embryos
              </div>
              <div className="sm:hidden mr-2 font-bold text-[12px] font-serif">
                Number of
                <br />
                Embryos
              </div>
              <select
                id="method"
                name="method"
                onChange={(e) => handleData('embryosTransferred', e.target.value)}
                value={data?.embryosTransferred}
                className="w-20 mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] font-serif"
              >
                <option value={1} className="font-bold text-[12px] font-serif">
                  1
                </option>
                <option value={2} className="font-bold text-[12px] font-serif">
                  2
                </option>
                <option value={3} className="font-bold text-[12px] font-serif">
                  3
                </option>
              </select>
            </div>
            <div className="flex flex-col">
              <div className="hidden sm:block mr-2 mb-1 font-bold text-[12px] font-serif">
                Embryo(s) Age
              </div>
              <div className="sm:hidden mr-2 mb-1 font-bold text-[12px] font-serif">
                Embryo(s)
                <br />
                Age
              </div>
              <input
                onChange={(e) => handleData('embryoAge', e.target.value)}
                value={data?.embryoAge}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
            <div className="flex flex-col">
              <div className="hidden sm:block mr-2 mb-1 font-bold text-[12px] font-serif">
                Embryo(s) Grade
              </div>
              <div className="sm:hidden mr-2 mb-1 font-bold text-[12px] font-serif">
                Embryo(s)
                <br />
                Grade
              </div>
              <input
                onChange={(e) => handleData('embryoGrade', e.target.value)}
                value={data?.embryoGrade}
                className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none w-[90%]`}
                type="text"
                defaultChecked={false}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 items-center py-2">
            <CommunityCheckbox
              label="The transfer was into a surrogate"
              isChecked={data?.surrogate}
              handleOnChange={() => handleData('surrogate', data?.surrogate ? false : true)}
            />
          </div>
          <div className="grid grid-flow-col items-center">
            <div className="mr-2 font-bold text-[12px] font-serif">Transfer Outcome</div>
            <select
              id="method"
              name="method"
              onChange={(e) => handleData('transferOutcome', e.target.value)}
              value={data?.transferOutcome}
              className="w-[170px] mt-1 block w-full py-2 pl-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none font-bold text-[12px] leading-[36px] font-serif"
            >
              <option value={'Did Not Implant'} className="font-bold text-[12px] font-serif">
                Did Not Implant
              </option>
              <option value={'Singleton Pregnancy'} className="font-bold text-[12px] font-serif">
                Singleton Pregnancy
              </option>
              <option value={'Multiple Pregnancy'} className="font-bold text-[12px] font-serif">
                Multiple Pregnancy
              </option>
              <option value={'Ectopic Pregnancy'} className="font-bold text-[12px] font-serif">
                Ectopic Pregnancy
              </option>
              <option value={'Chemical Pregnancy'} className="font-bold text-[12px] font-serif">
                Chemical Pregnancy
              </option>
              <option value={'Miscarriage'} className="font-bold text-[12px] font-serif">
                Miscarriage
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareTransferCard;
