import { useState } from 'react';
import OnboardingButton from '../onboarding/OnboardingButton';
import { Link } from 'react-router-dom';

const ShareSignup = ({ handleSignup }) => {
  // TODO: make sure signup works on both sides
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [username, setUsername] = useState('');
  const [sex, setSex] = useState('');
  const [birthday, setBirthday] = useState(''); //today
  const [havePartner, setHavePartner] = useState('');
  const [partnerBirthday, setPartnerBirthday] = useState('');
  const [partnerSex, setPartnerSex] = useState('');
  const [conditions, setConditions] = useState([]);
  const [race, setRace] = useState('');

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    // const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    // return re.test(password);
    return true;
  };

  const validateUsername = (username) => {
    const re = /^\S{3,}$/;
    return re.test(username);
  };

  const validateDate = (date) => {
    // check age requirement
    return true;
  };

  //   const handleBack = (e) => {
  //     setStep(0);
  //   };

  const enableSignup = () => {
    // Check if all inputs are filled correctly
    if (!validateEmail(email)) return false;
    if (!validatePassword(password)) return false;
    if (!validateUsername(username)) return false;
    if (!validateDate(birthday)) return false;
    if (havePartner === '') return false;
    if (
      havePartner === 'Yes' &&
      (!validateDate(partnerBirthday) || partnerBirthday === '' || partnerSex === '')
    )
      return false;

    if (race === '') return false;

    return true;
  };

  const handleSignupData = () => {
    const body = {
      username: username,
      password: password,
      email: email,
      userData: {
        birthday: birthday,
        gender: sex,
        havePartner: havePartner === 'Yes',
        conditions: conditions,
        race: race
      },
      journey: [],
      shareUser: true,
      dashboardType: 'curious'
    };

    if (havePartner === 'Yes') {
      body.userData.partnerBirthday = partnerBirthday;
      body.userData.partnerGender = partnerSex;
    }

    handleSignup(body);
  };

  return (
    <div className="bg-white w-full h-screen flex flex-col pt-8 pb-8">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-2 mb-5 ml-3">Sign Up</p>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="mb-2">
            <div className="text-lg font-bold">email</div>
            <div className="text-[12px] text-footer-grey">*We will never share this publicly</div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateEmail(email) || email === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">password</div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validatePassword(password) || password === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">password confirmation</div>
            <input
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                password === passwordConfirm || passwordConfirm === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">username</div>
            <div className="text-[12px] text-footer-grey">
              *Only your username will be associated with your posts. You can make your username
              unidentifiable to remain anonymous.{' '}
            </div>

            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateUsername(username) || username === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">birthday</div>
            <div className="text-[12px] text-footer-grey">*We will never share this publicly</div>
            <input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateDate(birthday) ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">sex assigned at birth</div>
            <select
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value="" disabled>
                Select Sex
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">race</div>
            <select
              value={race}
              onChange={(e) => {
                setRace(e.target.value);
              }}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value="" disabled>
                Select Race
              </option>
              <option value="hispanic">Hispanic</option>
              <option value="white">White</option>
              <option value="black">Black or African American</option>
              <option value="native">American Indian and Alaska Native</option>
              <option value="asian">Asian</option>
              <option value="nativePacific">Native Hawaiian and Other Pacific</option>
              <option value="islander">Islander Alone</option>
              <option value="other">Some Other Race</option>
              <option value="multiracial">Multiracial</option>
            </select>
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">have a partner</div>
            <select
              value={havePartner}
              onChange={(e) => {
                setHavePartner(e.target.value);
              }}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value={''} disabled>
                Select Partner
              </option>
              <option value={'Yes'}>Yes</option>
              <option value={'No'}>No</option>
            </select>
          </div>
          {havePartner === 'Yes' ? (
            <div>
              <div className="mb-2">
                <div className="text-lg font-bold">partner's birthday</div>
                <input
                  type="date"
                  value={partnerBirthday}
                  onChange={(e) => setPartnerBirthday(e.target.value)}
                  className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                    validateDate(partnerBirthday) || partnerBirthday === '' ? '' : 'border-red-500'
                  }`}
                />
              </div>
              <div className="mb-2">
                <div className="text-lg font-bold">partner's sex assigned at birth</div>
                <select
                  value={partnerSex}
                  onChange={(e) => setPartnerSex(e.target.value)}
                  className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
                >
                  <option value="" disabled>
                    Select Sex
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mt-4 text-[10px] text-font-grey focus:outline-none">
          By signing up you agree to The Fertility Plan’s{' '}
          <Link to="/terms" className="underline hover:text-blue-500">
            Terms
          </Link>{' '}
          and acknowledge reviewing our{' '}
          <Link to="/privacy" className="underline hover:text-blue-500">
            Privacy Policy
          </Link>
          .
        </div>
        <div className="mt-4">
          <OnboardingButton disabled={!enableSignup()} onClick={() => handleSignupData()}>
            Sign Up to Share Your Story
          </OnboardingButton>
        </div>
      </div>
    </div>
  );
};

export default ShareSignup;
