import { useContext, useState } from 'react';
import OnboardingButton from '../onboarding/OnboardingButton';
import { SessionContext } from '../../context/SessionContext';

const ShareLogin = ({
  setStep,
  handleError,
  handleLogin,
  handleLocalError
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleBack = () => {
    setStep(0);
  };

  return (
    <div className="bg-white w-full h-full flex flex-col pt-8 pb-8">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-2  mb-10">
          Log back in to <br /> Share Your Story
        </p>
      </div>

      <div
        style={{ height: '60px' }}
        className="flex items-center rounded-md border-2 border-black px-4 py-5 mb-3"
      >
        <input
          type="email"
          placeholder="Enter Email"
          className="bg-transparent outline-none flex-grow text-center text-xl"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </div>
      <div
        style={{ height: '60px' }}
        className="flex items-center rounded-md border-2 border-black px-4 py-5 mb-3"
      >
        <input
          type="password"
          placeholder="Enter Password"
          className="bg-transparent outline-none flex-grow text-center text-xl"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </div>
      <div className="mb-3">
        <OnboardingButton onClick={() => handleLogin({ email: email, password: password })}>
          Login with Email
        </OnboardingButton>
      </div>
      {<OnboardingButton onClick={handleBack}>Back to Onboarding</OnboardingButton>}
      <div>{handleLocalError ? `User with email does not exist or wrong password.` : ''}</div>
      <div>{handleError ? `Unable to login. No data exists at this Google account` : ''}</div>
      <img src="/assets/onboarding2.svg" alt="" className="h-1/3 w-auto mx-auto mt-12" />
    </div>
  );
};

export default ShareLogin;
