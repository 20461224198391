import React from 'react';

const AnswerOption = ({ children, handleStep, answer, step }) => {
  return (
    <button
      onClick={() => handleStep(step, answer, answer.value)}
      style={{ height: '60px' }}
      className="w-full bg-button-grey p-4 my-3 font-sans text-black text-xl flex items-center justify-center"
    >
      {children}
    </button>
  );
};

export default AnswerOption;
