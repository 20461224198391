import React, { useContext } from 'react';
import OnboardingButton from '../onboarding/OnboardingButton';

const QuickShareStart = ({ setStep }) => {
  const handleStart = () => {
    setStep(1);
  };

  return (
    <div className="bg-white w-full h-full flex flex-col justify-between">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-5">
          Share What Worked For You
        </p>
        <p className="font-sans text-left leading-6 text-lg my-5">
          The fertility journey can be lonely and daunting.
          <br /> <br />
          Hearing about your IVF cycle or egg freezing story can be immensely valuable to those
          navigating the fertility journey.
          <br /> <br />
          Your anonymous story can bring comfort and hope to someone looking for answers. Please
          share your experience with us.
        </p>
        <img src="/assets/onboarding1.svg" alt="" className="w-4/5 h-auto mx-auto" />
      </div>
      <div className="flex flex-col justify-center mt-auto">
        <OnboardingButton onClick={handleStart}>Start</OnboardingButton>
      </div>
    </div>
  );
};

export default QuickShareStart;
