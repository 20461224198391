import { RouterProvider, createBrowserRouter, Outlet, Navigate } from 'react-router-dom';
import OnboardingPage from './pages/Onboarding';
import Header from './components/Header';
import ChecklistPage from './pages/Checklist';
import ResourcesPage from './pages/Resources';
import CommunityPage from './pages/Community';
import SettingsPage from './pages/Settings';
import LearnPage from './pages/Learn';
import SharePage from './pages/Share';
import AppLayout from './AppLayout';
import PostPage from './pages/Post';
import PostSearchPage from './pages/PostSearch';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';
import QuickSharePage from './pages/QuickShare';

const Layout = () => (
  <>
    <Header />
    <Outlet />
    <AppLayout />
  </>
);

const App = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Navigate to={`/checklist`} replace />
        },
        {
          path: '/stories',
          element: <CommunityPage />
        },
        {
          path: '/checklist',
          element: <ChecklistPage />
        },
        {
          path: '/resources',
          element: <ResourcesPage />
        },
        {
          path: '/learn',
          element: <LearnPage />
        },
        {
          path: '/settings',
          element: <SettingsPage />
        },
        {
          path: '/onboarding',
          element: <OnboardingPage />
        },
        {
          path: '/shareYourStory',
          element: <SharePage />
        },
        {
          path: '/anonShare',
          element: <QuickSharePage />
        },
        {
          path: '/post/:postId',
          element: <PostPage />
        },
        {
          path: '/search/',
          element: <PostSearchPage />
        },
        {
          path: '/privacy/',
          element: <PrivacyPage />
        },
        {
          path: '/terms/',
          element: <TermsPage />
        }
      ]
    }
  ]);
  return (
    <div className="overflow">
      <div className="w-full h-full max-w-screen-sm mx-auto">
        <RouterProvider router={router}></RouterProvider>
      </div>
    </div>
  );
};

export default App;
