import React from 'react';

const Checkbox = ({ isChecked, handleOnChange, className, checkboxSize, iconSize, bgColor }) => {
  const checkboxStyles = {
    width: checkboxSize,
    height: checkboxSize
  };

  const iconStyles = {
    width: iconSize,
    height: iconSize
  };

  return (
    <label className={`inline-flex items-center cursor-pointer relative ${className}`}>
      <input
        type="checkbox"
        className="absolute opacity-0 cursor-pointer"
        checked={isChecked}
        onChange={() => handleOnChange(isChecked)}
      />
      <div className={`border border-black bg-${bgColor}`} style={checkboxStyles}>
        {isChecked && (
          <svg className="text-black fill-current mx-auto" viewBox="0 0 20 20" style={iconStyles}>
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        )}
      </div>
    </label>
  );
};

export default Checkbox;
