export const dashboardConfig = {
  curious: {
    type: 'curious',
    title: "I'm just curious",
    cards: [
      {
        content: 'Is there anything I can do now to improve my fertility later?',
        linkText: 'Prep for later',
        linkURL:
          'https://medium.com/@thefertilityplan/is-there-anything-i-can-do-now-to-improve-my-fertility-later-4fcb163bcf13'
      },
      {
        content: 'Till when can I delay getting pregnant?',
        linkText: 'Truth about age and fertility',
        linkURL:
          'https://medium.com/@thefertilityplan/how-long-can-pregnancy-be-delayed-d07db4c8e446'
      },
      {
        content: 'Do I have any conditions that may affect my fertility?',
        linkText: 'How to find out',
        linkURL:
          'https://medium.com/@thefertilityplan/do-i-have-any-conditions-that-may-affect-my-fertility-11c3385c6ca1'
      }
    ],
    checklist: [
      {
        title: 'Fertility Preservation',
        cards: [
          { id: '0', content: "Learn about egg freezing and decide if it's right for you" },
          {
            id: '1',
            content: 'See if your work or insurance covers egg freezing and estimate costs'
          },
          { id: '2', content: 'Find a fertility clinic for egg freezing and make a treatment plan' }
        ]
      },
      {
        title: 'Healthy Lifestyle',
        cards: [
          {
            id: '3',
            content: 'Eliminate toxins from your systems (plastics, nicotine, certain drugs)'
          },
          { id: '4', content: 'Maintain BMI in healthy range and practice moderate exercise' },
          { id: '5', content: 'Manage stress levels' }
        ]
      },
      {
        title: 'Get More Insights',
        cards: [
          {
            id: '6',
            content: "(If you're not on hormonal birth control) track your cycle and ovulation"
          },
          {
            id: '7',
            content: 'Check if you have any symptons for conditions like PCOS or endometriosis'
          },
          { id: '8', content: 'Order at home testing to analyze your fertility levels' },
          { id: '9', content: 'If you experience irregular cycles, talk to your doctor' }
        ]
      }
    ]
  },
  soon: {
    type: 'soon',
    title: "I'll start trying soon",
    cards: [
      {
        content: "When I'm ready to try, how do I maximize my chances?",
        linkText: 'Baby making 101',
        linkURL:
          'https://medium.com/@thefertilityplan/how-do-i-maximize-my-chances-of-getting-pregnant-3288f5e77d97'
      },
      {
        content: 'What if I have trouble conceiving later on?',
        linkText: 'Fact, Fiction, or Fear?',
        linkURL:
          'https://medium.com/@thefertilityplan/what-if-i-have-trouble-conceiving-later-on-3711d10b90a7'
      },
      {
        content: 'How long will it take for me to get pregnant?',
        linkText: 'Math behind fertility',
        linkURL:
          'https://medium.com/@thefertilityplan/how-long-will-it-take-for-me-to-get-pregnant-212cc6256aee'
      }
    ],
    checklist: [
      {
        title: 'Get Up to Date',
        cards: [
          {
            id: '10',
            content:
              'Get up to date labs and a physical with your PCP.  Make sure you’re not behind on a pap smear.'
          },
          {
            id: '11',
            content:
              'Did you know you can’t get some vaccines while pregnant? Check if you’re up to date.'
          },
          {
            id: '12',
            content: 'Some dental work is difficult to do while pregnant. Go see a dentist.'
          }
        ]
      },
      {
        title: 'Boost your body',
        cards: [
          {
            id: '13',
            content: 'Remove toxins from your systems. Keep alcohol and caffeine in moderation.'
          },
          {
            id: '14',
            content:
              'Start taking essential supplements for pregnancy:  Folic Acid (or prenatal vitamin), Vitamin D, Omega 3 with High DHA content'
          }
        ]
      },
      {
        title: 'Learn About Your Body',
        cards: [
          {
            id: '15',
            content:
              'Consider going off hormonal birth control. It takes ~3 months to get back to normal cycles.'
          },
          {
            id: '16',
            content: 'Start tracking your cycle and find out when you ovulate.'
          }
        ]
      },
      {
        title: 'For Extra Credit',
        cards: [
          {
            id: '17',
            content: 'Order at home hormone and semen tests to analyze your fertility levels'
          },
          {
            id: '18',
            content:
              'Talk to your healthcare practitioner about taking supplements for egg quality such as CoQ10, Myo Inositol, NAC.'
          }
        ]
      }
    ]
  },
  trying: {
    type: 'trying',
    title: 'I am trying',
    cards: [
      {
        content: 'Am I doing everything I can to get pregnant? Should I be doing more?',
        linkText: 'Cover all the bases',
        linkURL: 'https://medium.com/@thefertilityplan/59cc2ec11589'
      },
      {
        content: "What if I have fertility related conditions I don't know about?",
        linkText: 'How to check',
        linkURL:
          'https://medium.com/@thefertilityplan/do-i-have-any-conditions-that-may-affect-my-fertility-11c3385c6ca1'
      },
      {
        content: "What if I can't conceive naturally?",
        linkText: 'There are always options',
        linkURL:
          'https://medium.com/@thefertilityplan/what-if-i-cant-conceive-naturally-db5ade7c2a89'
      }
    ],
    checklist: [
      {
        title: 'Find Out When You Ovulate',
        cards: [
          {
            id: '19',
            content:
              'Get ovulation predictor kits. Our pick: ClearBlue digital. Best budget pick: Premom'
          },
          {
            id: '20',
            content: 'Learn Basal Body Temperature. Measure every morning to see when the peak is'
          },
          {
            id: '21',
            content:
              'Learn about cervical mucus. Check for “egg-white” consistency when you’re ovulating'
          }
        ]
      },
      {
        title: 'Time Sex (or Insemination)',
        cards: [
          {
            id: '22',
            content:
              'Find your most fertile days. It’s typically 5 days before the ovulation to ovulation day'
          },
          {
            id: '23',
            content:
              'Try every other day during your fertile window. (You don’t need to try every day, especially if this gets stressful!)'
          },
          {
            id: '24',
            content: "Don't forget to keep having fun! Plan date nights, pick out lingerie!"
          }
        ]
      },
      {
        title: 'Maintain Optimal Health',
        cards: [
          {
            id: '25',
            content: 'Remove toxins from your systems. Keep alcohol and caffeine in moderation.'
          },
          {
            id: '26',
            content:
              'Keep taking prenatal vitamins, and talk to your healthcare practitioner about taking supplements for egg quality such as CoQ10, Myo Inositol, NAC, etc.'
          },
          {
            id: '27',
            content: 'Avoid X-rays, intense exercise, or overheating during your luteal phase'
          }
        ]
      },
      {
        title: 'For Extra Credit',
        cards: [
          {
            id: '28',
            content: "You can ask your doctor for an ultrasound to see when you're ovulating"
          },
          {
            id: '29',
            content:
              'Consider alternative (but pricier) tools for finding when you ovulate. Like Mira'
          },
          {
            id: '30',
            content: 'Order at home hormone and semen tests to analyze your fertility levels'
          }
        ]
      }
    ]
  },
  tryingLong: {
    type: 'tryingLong',
    title: "I've been trying",
    cards: [
      {
        content: "What if I have conditions I don't know about?",
        linkText: 'How to check',
        linkURL:
          'https://medium.com/@thefertilityplan/do-i-have-any-conditions-that-may-affect-my-fertility-11c3385c6ca1'
      },
      {
        content: 'How much will treatments cost?',
        linkText: 'Run estimates',
        linkURL: 'https://medium.com/@thefertilityplan/how-much-will-treatment-cost-6a6de6a3949c'
      },
      {
        content: 'What expert should I go see?',
        linkText: 'Learn about your options',
        linkURL:
          'https://medium.com/@thefertilityplan/ive-been-trying-now-i-want-to-see-a-doctor-whom-should-i-go-see-808dab69e6cc'
      }
    ],
    checklist: [
      {
        title: 'Talk to Your Doctor',
        cards: [
          {
            id: '31',
            content:
              'Meet with your PCP or OB GYN - check if your levels are in range (TSH, AMH, Prolactin, Vitamin D)'
          },
          {
            id: '32',
            content:
              'Contact a fertility clinic for an appointment (Keep in mind that average wait time for a clinic is 3-5 months)'
          }
        ]
      },
      {
        title: 'With a Fertility Clinic',
        cards: [
          { id: '33', content: 'Get blood work to test for hormones of male and female partners.' },
          { id: '34', content: 'Get a semen analysis if you have a male partner' },
          {
            id: '35',
            content:
              'Get a HSG to check for any blockage in the Fallopian tubes (25% of infertile cases are caused by this)'
          },
          {
            id: '36',
            content: 'Get an ultrasound to check for a uterine study and ovarian reserve'
          }
        ]
      },
      {
        title: 'Maintain Optimal Health',
        cards: [
          {
            id: '37',
            content:
              "Treat your body like you're pregnant. Avoid X-rays, hot yoga, intense exercise, alcohol etc."
          },
          {
            id: '38',
            content:
              'Keep taking prenatal vitamins, and talk to your healthcare practitioner about taking supplements for egg quality such as CoQ10, Myo Inositol, NAC, etc.'
          },
          {
            id: '39',
            content:
              'Keep tracking your ovulation and take your shot during the fertile window. We know it can get frustrating, but keeping stress level low is important!'
          }
        ]
      },
      {
        title: 'For Extra Credit',
        cards: [
          {
            id: '40',
            content:
              'Look into whether further testing (like genetic testing, DNA fragmentation in sperm, or endometriosis laparoscopy) can give you more insights into your situation. But do your own research because some of these tests may not be informative to everyone.'
          }
        ]
      }
    ]
  },
  tryingLongRE: {
    type: 'tryingLongRE',
    title: "I've seen a doctor",
    cards: [
      {
        content: 'How will I pay for treatment?',
        linkText: 'Here are some resources',
        linkURL: 'https://medium.com/@thefertilityplan/how-will-i-pay-for-treatment-acb372329265'
      },
      {
        content: 'How can I improve my chances of the treatments working?',
        linkText: 'Tips and Resources',
        linkURL: 'https://medium.com/@thefertilityplan/95d5070aceca'
      },
      {
        content: 'What’s the best course of treatment for me?',
        linkText: 'Learn about your options',
        linkURL:
          'https://medium.com/@thefertilityplan/whats-the-best-course-of-treatment-for-me-7a892e288b3'
      }
    ],
    checklist: [
      {
        title: 'At the Fertility Clinic',
        cards: [
          {
            id: '41',
            content: 'Conduct tests with your doctor to diagnose if there are any known conditions'
          },
          {
            id: '42',
            content:
              'See if IUI is a good option for you. Keep in mind that IUI effectiveness is highly correlated with age. Our doctor doesn’t recommend IUI for those over 40.'
          }
        ]
      },
      {
        title: 'In Vitro Fertilization',
        cards: [
          {
            id: '43',
            content:
              'Make a treatment plan with your doctor. Your doctor will decide what the stimulation protocol will be, the type of fertilization (PICSI, ICSI, or IVF), the type of transfer (fresh or frozen), and whether to do genetic testing or not. '
          },
          {
            id: '44',
            content:
              'Make sure you learn about their treatment plans for you and the rationale behind their decisions.'
          },
          {
            id: '45',
            content:
              'If you have any questions or don’t agree with the treatment plan, be prepared to ask questions.'
          }
        ]
      },
      {
        title: 'Maintain Optimal Health',
        cards: [
          {
            id: '46',
            content:
              "Keep treating your body like you're pregnant. Avoid X-rays, hot yoga, intense exercise, alcohol etc."
          },
          {
            id: '47',
            content:
              'Take supplements for a healthy pregnancy and improved egg quality: Folic Acid, Vitamin D, Omega 3, CoQ10, Myo Inositol'
          }
        ]
      },
      {
        title: 'For Extra Credit',
        cards: [
          {
            id: '48',
            content:
              'Infertility can be an isolating process. Consider joining a support group like RESOLVE or seeing a therapist who specializes in reproductive trauma. '
          },
          {
            id: '49',
            content:
              'Give fertility acupuncture a try. Some studies suggest that acupuncture can help improve fertility treatment outcomes. But be sure to go to a trustworthy, licensed acupuncturist.'
          },
          {
            id: '50',
            content:
              'Traditional Chinese Medicine believes that keeping your body warm is conducive to conceiving. Try wearing socks to keep your feet warm.'
          }
        ]
      }
    ]
  }
};
