import React from 'react';
import ResourceCard from './ResourceCard';

const ResourceList = ({ data, setSelectedResource }) => {
  return (
    <div className="mx-3">
      {data.map((card, i) => (
        <ResourceCard setSelectedResource={setSelectedResource} key={i} card={card} />
      ))}
    </div>
  );
};

export default ResourceList;
