import React from 'react';
import Checkbox from '../ui/Checkbox';

const CommunityCheckbox = ({ label, isChecked, handleOnChange, className }) => {
  return (
    <div className={`flex flex-row mt-2 items-center ${className}`}>
      <Checkbox
        isChecked={isChecked}
        handleOnChange={handleOnChange}
        checkboxSize="20px"
        iconSize="18px"
      />

      <label
        className="text-[12px] font-serif font-bold leading-[16px] ml-2"
        htmlFor="femaleFactors"
      >
        {label}
      </label>
    </div>
  );
};

export default CommunityCheckbox;
