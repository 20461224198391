import React, { useState } from 'react';
import ResourceList from '../components/resources/ResourceList';
import ResourceDetailsPage from './ResourcesDetails';
import DashboardLayout from './DashboardLayout';

const resourceListData = [
  { title: 'Detox Tips' },
  { title: 'Ovulation Tracking' },
  { title: 'Egg & Lining Supplements', fontSize: 'text-lg' },
  { title: 'Sperm Supplements' },
  { title: 'Diagnostics Testing' },
  { title: 'Acupuncturists' },
  { title: 'Nutrionists' },
  { title: 'Fertility Coaches' },
  { title: 'Therapists & Emotional Support', fontSize: 'text-lg' },
  { title: 'Fertility Clinic' },
  { title: 'Books & Courses' },
  { title: 'DIY Tips' },
  { title: 'Alternative Approaches' }
];

const ResourcesPage = () => {
  const [selectedResource, setSelectedResource] = useState(null);

  return (
    <DashboardLayout currentPageIndex={2}>
      <div className="pb-5">
        {selectedResource ? (
          <ResourceDetailsPage
            title={selectedResource.title}
            setSelectedResource={setSelectedResource}
          />
        ) : (
          <div>
            <h2 className="text-title-black font-serif text-2xl font-bold leading-7">Resources</h2>
            <ResourceList setSelectedResource={setSelectedResource} data={resourceListData} />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ResourcesPage;
