import React, { useState } from 'react';
const AnswerAge = ({ children, handleStep, answer, step }) => {
  const [ageInput, setAgeInput] = useState('');

  const buttonStyle = `p-2 text-white mt-4 rounded-md ${
    ageInput?.length === 0 ? 'bg-purple bg-opacity-60 cursor-not-allowed' : 'bg-purple'
  }`;

  const handleInputChange = (event) => {
    let inputValue = event.target.value.replace(/\D/g, ''); // remove non-digit characters

    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3); // truncate input to three digits
    }

    setAgeInput(inputValue);
  };

  return (
    <>
      <div
        style={{ height: '60px' }}
        className="flex items-center rounded-md border-2 border-black px-4 py-5"
      >
        <input
          type="text"
          placeholder="Enter Age"
          pattern="[0-9]*"
          inputMode="numeric"
          className="bg-transparent outline-none flex-grow text-center text-xl"
          onChange={handleInputChange}
          value={ageInput}
        />
      </div>
      <button
        onClick={() => handleStep(step, answer, ageInput.toString())}
        className={buttonStyle}
        disabled={ageInput?.length === 0}
      >
        Next
      </button>
    </>
  );
};

export default AnswerAge;
