import React from 'react';
import Checklist from '../components/dashboard/Checklist';
import { dashboardConfig } from '../config/dashboard.config';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../context/SessionContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardLayout from './DashboardLayout';

const ChecklistPage = () => {
  const navigate = useNavigate();
  const { isLoading, dashboardType, setDashboardType, setCurrentStep, user } =
    useContext(SessionContext);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [displayedContent, setDisplayedContent] = useState(dashboardConfig[dashboardType]);

  const dashboardList = ['curious', 'soon', 'trying', 'tryingLong', 'tryingLongRE'];

  useEffect(() => {
    if (!isLoading && !user) {
      setCurrentStep(0);
      console.error('Invalid Dashboard');
      return navigate('/onboarding');
    }
    if (user) {
      // set dashboard to user's preference
      setDashboardType(user.dashboardType);
      // set users checkedBoxes
      setCheckedBoxes(user.checklist);
    }
  }, [user, isLoading]);

  const switchDashboardContent = (negative) => {
    const currDashboardIndex = dashboardList.indexOf(displayedContent.type);
    let newChecklistIndex;
    if (negative)
      newChecklistIndex = (currDashboardIndex - 1 + dashboardList.length) % dashboardList.length;
    else newChecklistIndex = (currDashboardIndex + 1 + dashboardList.length) % dashboardList.length;
    setDisplayedContent(dashboardConfig[dashboardList[newChecklistIndex]]);
  };
  const removeItem = (checkedBoxes, id) => {
    return checkedBoxes.filter((item) => item !== id);
  };

  const handleCheckBox = async (id, checked) => {
    let newCheckBoxes = [];
    if (checked) {
      newCheckBoxes = [...checkedBoxes, id];
    } else {
      newCheckBoxes = removeItem(checkedBoxes, id);
    }
    // update user
    setCheckedBoxes(newCheckBoxes);
    const updatedUser = await updateUser(newCheckBoxes);
  };

  const updateUser = async (checkedBoxes) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/user`,
      {
        checklist: checkedBoxes
      },
      {
        withCredentials: true
      }
    );
    // console.log('User Checkboxes updates: ', data.checklist);
    return data;
  };

  return (
    <DashboardLayout currentPageIndex={1}>
      <div className="md:overflow-y-auto md:scrollbar-thumb-gray-300 md:scrollbar-track-gray-100">
        <div className="flex flex-row justify-between items-center mt-4">
          <h2 className="font-serif font-bold text-2xl leading-7 mt-4 mb-4">
            Your Fertility Checklist
          </h2>
        </div>
        <div className="flex flex-row justify-between items-center">
          <button
            className="font-serif font-bold text-lg text-center leading-7 ml-2"
            onClick={() => switchDashboardContent(true)}
          >
            {'<'}
          </button>
          <h2 className="font-serif font-bold text-xl text-center mx-auto mt-2 mb-2">
            {`“${displayedContent?.title}”`}
          </h2>
          <button
            className="font-serif font-bold text-lg text-center leading-7 ml-2"
            onClick={() => switchDashboardContent(false)}
          >
            {'>'}
          </button>
        </div>
        {displayedContent?.checklist?.map((item, i) => {
          return (
            <Checklist
              key={i}
              title={item?.title}
              contentList={item?.cards}
              handleCheckBox={handleCheckBox}
              checkedBoxes={checkedBoxes}
            />
          );
        })}
      </div>
    </DashboardLayout>
  );
};

export default ChecklistPage;
