export const donorString = (id) => {
  switch (id) {
    case 'eggs':
      return 'Donor Eggs';
    case 'sperm':
      return 'Donor Sperm';
    case 'embryos':
      return 'Donor Embryos';
    case 'none':
      return 'None';
    default:
      return '';
  }
};
