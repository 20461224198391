import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../context/SessionContext';
import { useParams, useNavigate } from 'react-router-dom';
import { ShareContext } from '../context/ShareContext';

import axios from 'axios';
import PostTimed from '../components/post/PostTimed';
import PostIUI from '../components/post/PostIUI';
import PostFreezing from '../components/post/PostFreezing';
import PostIVF from '../components/post/PostIVF';
import PostTag from '../components/post/PostTag';
import PostAgeTag from '../components/post/PostAgeTag';

const PostPage = () => {
  const navigate = useNavigate();

  const { user, isLoading } = useContext(SessionContext);
  const { postId } = useParams(); // Get the postId from the URL
  const [notFoundError, setNotFoundError] = useState(false);

  const [post, setPost] = useState({});
  console.log(post);
  const titles = {
    timed: 'Success From Timed Intercourse',
    iui: 'Success From IUI',
    ivf: 'Success From IVF',
    freezing: 'Successful Egg/ Embryo Freezing'
  };
  useEffect(() => {
    const getPost = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/post/${postId}`);
        if (data) {
          setPost(data);
        }
      } catch (err) {
        console.log(err);
        setNotFoundError(true);
      }
    };

    getPost();
  }, [postId]);

  const showMethod = () => {
    if (post.method === 'timed') {
      return <PostTimed post={post} />;
    } else if (post.method === 'iui') {
      return <PostIUI post={post} />;
    } else if (post.method === 'ivf') {
      return <PostIVF post={post} />;
    } else if (post.method === 'freezing') {
      return <PostFreezing post={post} />;
    }
  };
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="bg-white w-full h-full p-5 overflow-auto">
      <div className="flex items-center mb-5 mt-2">
        <button className="focus:outline-none mb-1 mr-2" onClick={handleBack}>
          <img
            style={{ width: '30px', height: '30px' }}
            src={'/assets/arrow_back.svg'}
            alt={'arrow'}
          />
        </button>
        <h2 className="text-0E0E0E font-serif text-[20px] font-bold leading-7">
          {post.title ? post.title : null}
        </h2>
      </div>
      <h2 className="text-footer-grey font-serif text-[20px] font-bold leading-7">
        {post.method ? titles[post.method] : null}
      </h2>
      <div className="flex flex-row flex-wrap gap-2 mt-2">
        {post.author ? (
          <div className="p-1">
            {post.author?.username ? `@${post.author.username}` : `@${post.author?._id}`}
          </div>
        ) : null}

        {post?.age ? <PostAgeTag age={post?.age} /> : null}
        {post?.tags?.map((tag, i) => (
          <PostTag tag={tag} key={i} />
        ))}
      </div>
      {notFoundError ? <div className={'text-[40px]'}>404 POST NOT FOUND</div> : null}
      {showMethod()}
    </div>
  );
};

export default PostPage;
