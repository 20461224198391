import { useContext, useEffect, useState } from 'react';
import OnboardingStart from '../components/onboarding/OnboardingStart';
import OnboardingCard from '../components/onboarding/OnboardingCard';
import { SessionContext } from '../context/SessionContext';
import { onboardingConfig } from '../config/onboarding.config';
import { useNavigate } from 'react-router-dom';
import OnboardingFinish from '../components/onboarding/OnboardingFinish';
import OnboardingLogin from '../components/onboarding/OnboardingLogin';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import OnboardingSignup from '../components/onboarding/OnboardingSignup';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OnboardingPage = () => {
  const navigate = useNavigate();
  const {
    currentStep,
    setCurrentStep,
    addToUserPath,
    setDashboardType,
    userPath,
    setLocalDashboard
  } = useContext(SessionContext);
  const steps = onboardingConfig.steps;

  const [skipStep, setSkipStep] = useState(false);
  const [handleLoginError, setHandleLoginError] = useState(false);
  const [handleLocalLoginError, setHandleLocalLoginError] = useState(false);
  const [handleSignupError, setHandleSignupError] = useState(false);
  let query = useQuery();
  // console.log('current step: ', currentStep);
  // console.log('user path: ', userPath);

  // on refresh go back to start
  useEffect(() => {
    // handle login failure

    let failure = query.get('failure');
    if (failure) {
      // handle login failure
      if (failure === 'login') {
        setHandleLoginError(true);
      } else if (failure === 'signup') {
        setHandleSignupError(true);
      }
    }

    setCurrentStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = async (loginType) => {
    // Complete onboarding logic
    setDashboardType(getDashboardType());
    if (loginType === 'google') handleFinishGoogle();
    if (loginType === 'local') handleFinishLocal();
  };

  const getDashboardType = () => {
    // Set dashboard based on last option in user path
    const lastAnswer = userPath[userPath.length - 1].id;

    // TODO: enum the possible dashboards
    switch (lastAnswer) {
      case '8':
        return 'curious';
      case '9':
        return 'soon';
      case '10':
        return 'trying';
      case '13' || '14':
        return 'tryingLong';
      case '15':
        return 'tryingLongRE';
      default:
        break;
    }
  };

  const handleFinishGoogle = async () => {
    try {
      setLocalDashboard('logged');
      // update user
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          dashboardType: getDashboardType(),
          journey: userPath
        },
        {
          withCredentials: true
        }
      );

      setCurrentStep(0);
      navigate('/');
    } catch (err) {
      console.log('ERROR updating user: ', err);
    }
  };

  const handleFinishLocal = async () => {
    // go to signup page
    setCurrentStep(-5);
  };

  const handleSignup = async (body) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/local/signup`,
        body,
        {
          withCredentials: true
        }
      );

      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}`;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = async (body) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/local/login`, body, {
        withCredentials: true
      });

      window.location.href = `${process.env.REACT_APP_DOMAIN_URL}`;
    } catch (err) {
      console.log(err);
      setHandleLocalLoginError(true);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const birthdayPath = userPath.find((item) => item.step === 1).answer;
      const sexPath = userPath.find((item) => item.step === 2).answer;
      const havePartner = userPath.find((item) => item.step === 3).answer;

      setDashboardType(getDashboardType());
      let stateObject = {
        dashboardType: getDashboardType(),
        userPath: userPath,
        userData: {
          birthday: birthdayPath,
          gender: sexPath,
          havePartner: havePartner === 'Yes'
        }
      };
      if (havePartner === 'Yes') {
        const partnerBirthday = userPath.find((item) => item.step === 3.1).answer;
        stateObject.userData.partnerBirthday = partnerBirthday;
        const partnerSex = userPath.find((item) => item.step === 3.2).answer;
        stateObject.userData.partnerGender = partnerSex;
      }
      // Convert state str to base64
      const stateValue = encodeURIComponent(btoa(JSON.stringify(stateObject)).toString('base64'));
      // Google Oauth redirect
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/signup?state=${stateValue}`;
    } catch (error) {
      console.error('An error occurred while logging in: ', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/login`;
    } catch (error) {
      console.error('An error occurred while logging in: ', error);
    }
  };

  const handleStep = async (step, answer, answerValue) => {
    const newStep = Math.floor(currentStep) + answer.step;
    // Save the choice user made to path
    addToUserPath({ id: answer.id, step: step, answer: answerValue });
    if (answer.step === -1) return setCurrentStep(-1);
    // Takes the integer value of the current step and adds to it
    setCurrentStep(newStep);
  };

  const indexOfCurrentStep = (stepValue) => {
    // Since step can have multiple parts, we process steps by index
    const index = steps.findIndex((step) => step.stepValue === stepValue);
    // Return 0 if index is negative
    return index > 0 ? index : 0;
  };

  // Handle onboard routing
  const onboardRouter = () => {
    if (skipStep === true || handleLoginError) {
      return (
        <OnboardingLogin
          handleGoogleLogin={handleGoogleLogin}
          handleError={handleLoginError}
          handleLocalError={handleLocalLoginError}
          setSkipStep={setSkipStep}
          handleLogin={handleLogin}
        />
      );
    } else if (currentStep === 0) {
      return <OnboardingStart setSkipStep={setSkipStep} />;
    } else if (currentStep === -1 || handleSignupError) {
      return (
        <OnboardingFinish
          handleFinish={handleFinish}
          handleError={handleSignupError}
          handleGoogleSignup={handleGoogleSignup}
        />
      );
    } else if (currentStep === -5) {
      return <OnboardingSignup handleSignup={handleSignup} />;
    } else {
      // Take question and answers from config
      return (
        <OnboardingCard content={steps[indexOfCurrentStep(currentStep)]} handleStep={handleStep} />
      );
    }
  };

  return <div className="bg-white w-full h-full px-5 overflow-auto">{onboardRouter()}</div>;
};

export default OnboardingPage;
