import React from 'react';
import { Link } from 'react-router-dom';

const LearnCard = ({ card, color }) => {
  const colorVariants = {
    yellow: 'w-full bg-yellow rounded-lg shadow-lg p-4 mt-5 ',
    green: 'w-full bg-lighter-green rounded-lg shadow-lg p-4 mt-5'
  };
  return (
    <Link to={card.linkURL} target="_blank" rel="noopener noreferrer">
      <div
        className={colorVariants[color]}
        style={{ height: '160px', maxHeight: '160px', minHeight: '160px' }}
      >
        <div className="flex flex-col h-full justify-between">
          <h2 className="font-serif font-bold text-xl text-purple text-left">{card.content}</h2>
          <div className="text-left flex flex-row items-center">
            <div className="font-serif font-bold text-dark-green text-lg text-left">
              {' '}
              {`${card.linkText}`}
            </div>
            <img
              style={{ width: '17px', height: '15px' }}
              src={'/assets/arrow_right.svg'}
              alt={'arrow'}
              className="my-auto ml-2"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LearnCard;
