import { useLocation } from 'react-router-dom';

import { FrontChat } from './components/frontchat/FrontChat';

const AppLayout = () => {
  const location = useLocation();
  return (
    <div className="">
      {location.pathname !== '/onboarding' && location.pathname !== '/shareYourStory' && (
        <FrontChat />
      )}
    </div>
  );
};

export default AppLayout;
