import React from 'react';
import { Link } from 'react-router-dom';

export const DashboardFooter = ({ currentPageIndex }) => {
  const categories = [
    {
      name: 'Success Stories',
      path: '/stories',
      img: '/assets/connect.svg',
      imgSelected: '/assets/connect_selected.svg'
    },
    {
      name: 'Checklist',
      path: '/checklist',
      img: '/assets/checklist.svg',
      imgSelected: '/assets/checklist_selected.svg'
    },
    {
      name: 'Resources',
      path: '/resources',
      img: '/assets/resources.svg',
      imgSelected: '/assets/resources_selected.svg'
    },
    {
      name: 'Learn',
      path: '/learn',
      img: '/assets/learn.svg',
      imgSelected: '/assets/learn_selected.svg'
    },
    {
      name: 'Settings',
      path: '/settings',
      img: '/assets/settings.svg',
      imgSelected: '/assets/settings_selected.svg'
    }
  ];

  return (
    <div style={{ height: '80px' }} className="fixed bottom-0 w-full py-4 bg-turquoise">
      <div className="text-footer-grey mt-auto max-w-screen-sm mx-auto">
        <div className="container flex justify-around items-center mx-auto">
          {categories.map((category, index) => (
            <Link
              key={index}
              to={category.path} // Use the path from the categories array
              className="text-center block"
            >
              <img
                style={{ width: '30px', height: '30px' }}
                src={currentPageIndex === index ? category.imgSelected : category.img}
                alt={category.name}
                className="mx-auto mb-2"
              />
              <p
                style={currentPageIndex === index ? { color: 'black' } : {}}
                className="text-center text-xs font-semibold leading-3"
              >
                {category.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
