import React, { useContext } from 'react';
import OnboardingButton from '../onboarding/OnboardingButton';
import { SessionContext } from '../../context/SessionContext';
import OnboardingLoginButton from '../onboarding/OnboardingLoginButton';
import axios from 'axios';

const ShareStart = ({ setStep }) => {
  const { setCurrentStep, setUserPath } = useContext(SessionContext);

  const handleStart = () => {

    setStep(1);
  };

  const handleLogin = () => {
    setStep(-5);
  };

  return (
    <div className="bg-white w-full h-full flex flex-col justify-between">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-5">
          Share What Worked For You
        </p>
        <p className="font-sans text-left leading-6 text-lg my-5">
          The fertility journey can be very lonely and daunting at times.
          <br /> <br />
          Sharing your path to a successful pregnancy or egg freezing can be immensely valuable to
          those navigating the fertility journey. Please share what worked for you.
          <br /> <br />
          Your anonymous story might bring comfort and hope to someone looking for answers.
        </p>
        <img src="/assets/onboarding1.svg" alt="" className="w-4/5 h-auto mx-auto" />
      </div>
      <div className="flex flex-col justify-center mt-auto">
        <OnboardingButton onClick={handleStart}>Start</OnboardingButton>
        <OnboardingLoginButton onClick={handleLogin}>Log Back In</OnboardingLoginButton>
      </div>
    </div>
  );
};

export default ShareStart;
