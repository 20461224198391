import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommunityList from '../components/community/CommunityList';
import CommunitySearch from '../components/community/CommunitySearch';
import DashboardLayout from './DashboardLayout';

const communityHeaders = [
  { title: 'PCOS', filter: 'tags=pcos' },
  { title: 'Low Sperm Count', filter: 'tags=lowSperm' },
  { title: 'Endometriosis', filter: 'tags=endometriosis' },
  { title: 'Previous Miscarriages', filter: 'tags=previousMiscarriages' },
  { title: 'IVF Stories', filter: 'method=ivf' },
  { title: 'Egg Freezing Stories', filter: 'method=freezing' }
];

const CommunityPage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState('home');

  const handleSearch = () => {
    setPage('search');
  };

  const handleShare = () => {
    navigate('/shareyourstory');
  };

  const searchRouter = () => {
    if (page === 'search') {
      return <CommunitySearch setPage={setPage} />;
    } else {
      return (
        <div>
          <h2 className="text-title-black font-serif text-2xl font-bold leading-7 mb-7">
            Successful Fertility Stories
          </h2>
          <div className="mb-6">
            <CommunityList data={communityHeaders} handleSearch={handleSearch} />
          </div>
          <div className="grid grid-cols-2 p-5 gap-5">
            <button
              onClick={handleSearch}
              className="border border-black p-8 py-16 text-[16px] font-bold leading-[20px] font-serif"
            >
              Search by Conditions or Methods
            </button>
            <button
              onClick={handleShare}
              className="border border-black p-8 py-14 text-[16px] font-bold leading-[20px] font-serif"
            >
              Share your Successful Fertility Story
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <DashboardLayout currentPageIndex={0}>
      <div className="pb-5">{searchRouter()}</div>
    </DashboardLayout>
  );
};

export default CommunityPage;
