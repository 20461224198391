import React from 'react';
import CommunityCard from './CommunityCard';

const CommunityList = ({ data, handleSearch }) => {
  return (
    <div className="mx-3">
      <span className="text-black text-center font-serif text-sm font-normal leading-tight mx-3">
        Read from people who have
      </span>
      {data.map((card, i) => (
        <CommunityCard
          handleSearch={handleSearch}
          key={i}
          card={card}
          color={i % 2 === 0 ? 'yellow' : 'green'}
        />
      ))}
    </div>
  );
};

export default CommunityList;
