import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../context/SessionContext';
import { ShareContext } from '../../context/ShareContext';
import CommunityButton from '../community/CommunityButton';
import CommunityCheckbox from '../community/CommunityCheckbox';
import CommunitySubheader from '../community/CommunitySubheader';
import CommunityFactor from '../community/CommunityFactor';
import { useNavigate } from 'react-router-dom';
import CommunityIntegerInput from '../community/CommunityIntegerInput';

const QuickShareTags = ({ setStep }) => {
  const navigate = useNavigate();
  const [invalidTags, setInvalidTags] = useState(false);
  const [invalidRace, setInvalidRace] = useState(false);
  const {  tags, setTags, age, setAge, race, setRace } = useContext(ShareContext);
  const { user } = useContext(SessionContext);

  useEffect(() => {
    if (user && user?.userData?.birthday) {
      const calculateAge = (birthdate) => {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }

        return age;
      };

      const age = calculateAge(user.userData.birthday);
      setAge(age);
    }
  }, [user]);

  const switchSingleTag = (tagId) => {
    setTags((prevState) => ({
      ...prevState,
      [tagId]: { value: !tags[tagId].value, factor: tags[tagId].factor, name: tags[tagId].name }
    }));
  };

  const validateData = () => {
    let hasError = false;
    if (Object.values(tags).filter((tag) => tag.value).length === 0) {
      setInvalidTags(true);
      hasError = true;
    } else setInvalidTags(false);

    if (race === '' && !user?.userData?.race) {
      setInvalidRace(true);
      hasError = true;
    } else setInvalidRace(false);
    if (hasError) return;
    setStep(2);
  };

  const handleIntegerInput = (data, event) => {
    const newValue = event.target.value;
    if (!event.target.validity.valid) {
      setAge(0);
    } else {
      setAge(newValue);
    }
  };

  return (
    <div className="bg-white w-full h-full flex flex-col pb-2">
      <div className="flex flex-row justify-start">
        <button className="focus:outline-none mb-7 mr-2" onClick={() => setStep(0)}>
          <img
            style={{ width: '30px', height: '30px' }}
            src={'/assets/arrow_back.svg'}
            alt={'arrow'}
          />
        </button>
        <p className="font-serif font-bold text-2xl text-left leading-8 mb-3 mt-4">
          Share your <br /> Successful Fertility Story
        </p>
      </div>
      <div className="py-6 text-[12px] font-black font-serif font-bold leading-[16px] mb-2 md:mr-16">{`Your story is completely anonymous. Only the information you provide below will be shared.No identifying information will be associated with your story.  `}</div>
      <div>
        <CommunitySubheader className={'mt-5 mb-4 font-bold'} title={'Your Fertility Conditions'} />
        <CommunityFactor title="Female Factors" />
        <div className="flex flex-row mb-6 space-x-14">
          <div className="flex flex-col">
            <CommunityCheckbox
              label={'PCOS'}
              isChecked={tags.pcos.value}
              handleOnChange={() => switchSingleTag('pcos')}
            />
            <CommunityCheckbox
              label={'POI'}
              isChecked={tags.poi.value}
              handleOnChange={() => switchSingleTag('poi')}
            />
            <CommunityCheckbox
              label={'Tubal Blockage'}
              isChecked={tags.tubalBlockage.value}
              handleOnChange={() => switchSingleTag('tubalBlockage')}
            />
            <CommunityCheckbox
              label={'Adenomyosis'}
              isChecked={tags.adenomyosis.value}
              handleOnChange={() => switchSingleTag('adenomyosis')}
            />
            <CommunityCheckbox
              label={'Polyps'}
              isChecked={tags.polyps.value}
              handleOnChange={() => switchSingleTag('polyps')}
            />
          </div>
          <div className="flex flex-col">
            <CommunityCheckbox
              label={'DOR/ Low AMH'}
              isChecked={tags.dor.value}
              handleOnChange={() => switchSingleTag('dor')}
            />
            <CommunityCheckbox
              label={'Hypothalamic dysfunction'}
              isChecked={tags.hypothalamic.value}
              handleOnChange={() => switchSingleTag('hypothalamic')}
            />
            <CommunityCheckbox
              label={'Endometriosis'}
              isChecked={tags.endometriosis.value}
              handleOnChange={() => switchSingleTag('endometriosis')}
            />
            <CommunityCheckbox
              label={'Uterine Fibroids'}
              isChecked={tags.uterineFibroids.value}
              handleOnChange={() => switchSingleTag('uterineFibroids')}
            />
          </div>
        </div>
        <CommunityFactor title="Male Factors" />
        <div className="flex flex-row mb-6  space-x-2">
          <div className="flex flex-col">
            <CommunityCheckbox
              label={'Low Sperm Count'}
              isChecked={tags.lowSperm.value}
              handleOnChange={() => switchSingleTag('lowSperm')}
            />
            <CommunityCheckbox
              label={'Abnormal Morphology'}
              isChecked={tags.abnormalMorphology.value}
              handleOnChange={() => switchSingleTag('abnormalMorphology')}
            />
            <CommunityCheckbox
              label={'Ejaculation Issues'}
              isChecked={tags.ejaculationIssues.value}
              handleOnChange={() => switchSingleTag('ejaculationIssues')}
            />
          </div>
          <div className="flex flex-col">
            <CommunityCheckbox
              label={'Poor Sperm Motility'}
              isChecked={tags.poorMotility.value}
              handleOnChange={() => switchSingleTag('poorMotility')}
            />
            <CommunityCheckbox
              label={'Varicocele'}
              isChecked={tags.varicocele.value}
              handleOnChange={() => switchSingleTag('varicocele')}
            />
            <CommunityCheckbox
              label={'Hypogonadism (Low T)'}
              isChecked={tags.hypogonadism.value}
              handleOnChange={() => switchSingleTag('hypogonadism')}
            />
          </div>
        </div>
        <CommunityFactor className={`mb-0`} title={'Other Conditions'} />
        <div className="mt-1">
          <CommunityCheckbox
            label={'Previous Miscarriages'}
            isChecked={tags.previousMiscarriages.value}
            handleOnChange={() => switchSingleTag('previousMiscarriages')}
          />
          <CommunityCheckbox
            label={'Repeated Transfer Failures'}
            isChecked={tags.repeatedTransferFailures.value}
            handleOnChange={() => switchSingleTag('repeatedTransferFailures')}
          />
          <CommunityCheckbox
            label={'Auto Immune Disease'}
            isChecked={tags.autoImmune.value}
            handleOnChange={() => switchSingleTag('autoImmune')}
          />
          <CommunityCheckbox
            label={'Unexplained'}
            isChecked={tags.unexplained.value}
            handleOnChange={() => switchSingleTag('unexplained')}
          />
          <CommunityCheckbox
            label={'Not Infertile'}
            isChecked={tags.notInfertile.value}
            handleOnChange={() => switchSingleTag('notInfertile')}
          />
        </div>
      </div>
      {invalidTags && (
        <div className="text-red-500 mt-2 text-[12px] font-serif font-bold leading-[16px]">
          At least 1 condition must be selected. If you did egg/embryo freezing, you can select “Not
          Infertile.” If you don’t know of any conditions, you can select “Unexplained”
        </div>
      )}
      <div className="flex flex-row items-center mt-14">
        <CommunityFactor title="Age at Pregnancy/ Egg Freezing" />
        <CommunityIntegerInput
          className={'w-[45px] ml-auto mr-14'}
          value={age}
          data={'age'}
          onChange={handleIntegerInput}
        />
      </div>
      {!user?.userData?.race && (
        <select
          value={race}
          onChange={(e) => {
            setRace(e.target.value);
          }}
          className="w-[50%] text-[16px] border border-black bg-transparent rounded-md focus:outline-none"
        >
          <option value="" disabled>
            Select Race
          </option>
          <option value="hispanic">Hispanic</option>
          <option value="white">White</option>
          <option value="black">Black or African American</option>
          <option value="native">American Indian and Alaska Native</option>
          <option value="asian">Asian</option>
          <option value="nativePacific">Native Hawaiian and Other Pacific</option>
          <option value="islander">Islander Alone</option>
          <option value="other">Some Other Race</option>
          <option value="multiracial">Multiracial</option>
          <option value="na">Prefer Not to Answer</option>
        </select>
      )}
      <div className="flex flex-row items-center ml-2">
        <CommunityCheckbox
          isChecked={tags.lgbtq.value}
          handleOnChange={() => switchSingleTag('lgbtq')}
        />
        <div className="text-[10px] leading-[20px] mt-2 font-serif">
          This success story involves LGBTQ+ individuals
        </div>
      </div>
      {invalidRace && (
        <div className="text-red-500 mt-2 text-[12px] font-serif font-bold leading-[16px]">
          Must select a race
        </div>
      )}
      <div className="flex flex-col justify-center mt-10 ">
        <CommunityButton onClick={() => validateData()}>Next</CommunityButton>
      </div>
    </div>
  );
};

export default QuickShareTags;
