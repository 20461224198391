import { useState, useContext, useEffect } from 'react';
import { DashboardFooter } from '../components/dashboard/DashboardFooter';
import { FrontChat } from '../components/frontchat/FrontChat';
import { SessionContext } from '../context/SessionContext';
import { useNavigate } from 'react-router-dom';

const DashboardLayout = ({ currentPageIndex, children }) => {
  const navigate = useNavigate();

  const { user, isLoading, setCurrentStep } = useContext(SessionContext);

  useEffect(() => {
    if (!isLoading && !user) {
      setCurrentStep(0);
      console.error('Invalid Dashboard');
      return navigate('/onboarding');
    }
  }, [user, isLoading]);

  const loadDashboard = () => {
    return (
      <>
        {
          !isLoading ? (
            <div className="relative bg-white flex flex-col items-center">
              <div className="w-full h-full  mb-14 p-5 md:overflow-y-auto md:scrollbar-thumb-gray-300 md:scrollbar-track-gray-100 w-full max-w-screen-lg">
                {children}
              </div>

              <DashboardFooter currentPageIndex={currentPageIndex} />
            </div>
          ) : null /* TODO: setup loading here*/
        }
      </>
    );
  };

  return <>{loadDashboard()}</>;
};

export default DashboardLayout;
