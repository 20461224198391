import { useEffect, useState } from 'react';
import CommunityButton from './CommunityButton';
import CommunityCheckbox from './CommunityCheckbox';
import CommunitySubheader from './CommunitySubheader';
import { useNavigate } from 'react-router-dom';

const CommunitySearch = ({ setPage }) => {
  const navigate = useNavigate();
  const [dropdownsOpen, setDropdownsOpen] = useState(false);
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [race, setRace] = useState('');
  const [femaleDropdown, setFemaleDropdown] = useState(false);
  const [maleDropdown, setMaleDropdown] = useState(false);
  const [otherDropdown, setOtherDropdown] = useState(false);
  const [ovulationGroup, setOvulationGroup] = useState(false);
  const [uterineGroup, setUterineGroup] = useState(false);
  const [spermGroup, setSpermGroup] = useState(false);
  const [filterTags, setFilterTags] = useState({
    lgbtq: false,
    pcos: false,
    dor: false,
    poi: false,
    hypothalamic: false,
    tubalBlockage: false,
    endometriosis: false,
    adenomyosis: false,
    uterineFibroids: false,
    polyps: false,
    lowSperm: false,
    poorMotility: false,
    abnormalMorphology: false,
    varicocele: false,
    ejaculationIssues: false,
    hypogonadism: false,
    previousMiscarriages: false,
    repeatedTransferFailures: false,
    autoImmune: false,
    unexplained: false,
    notInfertile: false
  });

  const [filterMethods, setFilterMethods] = useState({
    timed: false,
    iui: false,
    ivf: false,
    freezing: false
  });

  const handleSingleMethod = (filterName) => {
    setFilterMethods((prevState) => ({
      ...prevState,
      [filterName]: !filterMethods[filterName]
    }));
  };

  const handleSingleFilter = (filterName) => {
    setFilterTags((prevState) => ({
      ...prevState,
      [filterName]: !filterTags[filterName]
    }));
  };

  const handleOvulationGroup = () => {
    if (!ovulationGroup)
      setFilterTags((prevState) => ({
        ...prevState,
        pcos: true,
        dor: true,
        poi: true,
        hypothalamic: true
      }));
    else {
      setFilterTags((prevState) => ({
        ...prevState,
        pcos: false,
        dor: false,
        poi: false,
        hypothalamic: false
      }));
    }
    setOvulationGroup(!ovulationGroup);
  };

  const handleUterineGroup = () => {
    if (!uterineGroup)
      setFilterTags((prevState) => ({
        ...prevState,
        uterineFibroids: true,
        polyps: true
      }));
    else {
      setFilterTags((prevState) => ({
        ...prevState,
        uterineFibroids: false,
        polyps: false
      }));
    }
    setUterineGroup(!uterineGroup);
  };

  const handleSpermGroup = () => {
    if (!spermGroup)
      setFilterTags((prevState) => ({
        ...prevState,
        lowSperm: true,
        poorMotility: true,
        abnormalMorphology: true
      }));
    else {
      setFilterTags((prevState) => ({
        ...prevState,
        lowSperm: false,
        poorMotility: false,
        abnormalMorphology: false
      }));
    }
    setSpermGroup(!spermGroup);
  };

  const handleFemaleDropdown = () => {
    setFemaleDropdown(!femaleDropdown);
  };
  const handleMaleDropdown = () => {
    setMaleDropdown(!maleDropdown);
  };
  const handleOtherDropdown = () => {
    setOtherDropdown(!otherDropdown);
  };

  const handleAge = (type, event) => {
    const newValue = event.target.value;

    if (!event.target.validity.valid) {
      if (type === 'minAge') {
        setMinAge('');
      } else {
        setMaxAge('');
      }
    } else {
      if (type === 'minAge') {
        setMinAge(newValue);
      } else {
        setMaxAge(newValue);
      }
    }
  };

  const allDropdownsClosed = () => {
    if (!femaleDropdown && !maleDropdown && !otherDropdown) return true;
    return false;
  };

  useEffect(() => {
    if (allDropdownsClosed()) setDropdownsOpen(false);
    else setDropdownsOpen(true);
  }, [maleDropdown, femaleDropdown, otherDropdown]);

  const validateAge = () => {
    if (Number(minAge) > Number(maxAge)) {
      setAgeError(true);
      return false;
    } else {
      setAgeError(false);
      return true;
    }
  };
  const handleSearch = () => {
    if ((minAge.length || maxAge.length) && !validateAge()) return;
    const selectedTags = Object.keys(filterTags).filter((key) => filterTags[key]);
    const selectedMethods = Object.keys(filterMethods).filter((key) => filterMethods[key]);

    const queryString = new URLSearchParams();

    if (selectedTags.length) {
      queryString.append('tags', selectedTags.join(','));
    }

    if (selectedMethods.length) {
      queryString.append('method', selectedMethods.join(','));
    }

    if (minAge.length) {
      queryString.append('minAge', minAge);
    }

    if (maxAge.length) {
      queryString.append('maxAge', maxAge);
    }

    if (race) {
      queryString.append('race', race);
    }
    // 3. Use `useNavigate` to navigate to the search page with the constructed query string.
    navigate(`/search/?${queryString.toString()}`);
  };
  return (
    <div className="bg-white w-full h-full flex flex-col pb-2">
      <div className="flex flex-row justify-start">
        <button className="focus:outline-none mb-11 mr-2" onClick={() => setPage('home')}>
          <img
            style={{ width: '30px', height: '30px' }}
            src={'/assets/arrow_back.svg'}
            alt={'arrow'}
          />
        </button>
        <p className="font-serif font-bold text-2xl text-left leading-8 mb-3 ">
          Search Successful <br /> Fertility Stories
        </p>
      </div>
      <div className="mx-3">
        <CommunitySubheader className={'mt-5 mb-4'} title={'By Demographic'} />
        <div className="flex flex-row w-full pr-8">
          <div className="flex flex-col mr-16">
            <div className="text-sm font-serif font-bold leading-[16px] mb-2">Age</div>
            <div className="flex flex-row">
              <input
                style={{ width: '40px' }}
                className="border border-black bg-transparent rounded-md"
                type="number"
                defaultChecked={false}
                value={minAge}
                onChange={(e) => handleAge('minAge', e)}
              />
              <div className="mx-3">to</div>
              <input
                style={{ width: '40px' }}
                className="border border-black bg-transparent rounded-md"
                type="number"
                defaultChecked={false}
                value={maxAge}
                onChange={(e) => handleAge('maxAge', e)}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-serif font-bold leading-[16px] mb-2">Race</div>
            <div className="flex flex-row">
              <select
                value={race}
                onChange={(e) => {
                  setRace(e.target.value);
                }}
                className="w-[50%] text-[16px] border border-black bg-transparent rounded-md focus:outline-none"
              >
                <option value="" disabled>
                  Select Race
                </option>
                <option value="all">All</option>
                <option value="hispanic">Hispanic</option>
                <option value="white">White</option>
                <option value="black">Black or African American</option>
                <option value="native">American Indian and Alaska Native</option>
                <option value="asian">Asian</option>
                <option value="nativePacific">Native Hawaiian and Other Pacific</option>
                <option value="islander">Islander Alone</option>
                <option value="other">Some Other Race</option>
                <option value="multiracial">Multiracial</option>
              </select>
            </div>
          </div>
        </div>
        <CommunityCheckbox
          label={'Filter for LGBTQ+ stories'}
          isChecked={filterTags.lgbtq}
          handleOnChange={() => handleSingleFilter('lgbtq')}
        />
        <CommunitySubheader
          className={`mb-0 ${dropdownsOpen ? 'mt-4' : 'mt-16'}`}
          title={'By Conditions'}
        />
        <CommunityCheckbox
          label={'Previous Loss/ Miscarriage(s)'}
          isChecked={filterTags.previousMiscarriages}
          handleOnChange={() => handleSingleFilter('previousMiscarriages')}
        />
        <CommunityCheckbox
          label={'Unexplained'}
          isChecked={filterTags.unexplained}
          handleOnChange={() => handleSingleFilter('unexplained')}
        />
        <CommunityCheckbox
          label={'Not Infertile'}
          isChecked={filterTags.notInfertile}
          handleOnChange={() => handleSingleFilter('notInfertile')}
        />
        <button className="flex flex-row mt-3 items-center" onClick={() => handleFemaleDropdown()}>
          <div className="text-[14px] font-serif font-bold leading-[16px] mr-2">
            {femaleDropdown ? '▼' : <img src={'/assets/dropdown_right.svg'} alt="Dropdown Right" />}
          </div>
          <div className="text-[14px] font-serif font-bold leading-[16px]">Female Factors</div>
        </button>
        {femaleDropdown ? (
          <div>
            <div className="flex flex-row mt-3">
              <CommunityCheckbox
                label={'Ovulation Issues'}
                isChecked={ovulationGroup}
                handleOnChange={handleOvulationGroup}
              />
            </div>
            <div className="flex flex-row ml-5">
              <div className="flex flex-col mr-[118px]">
                <CommunityCheckbox
                  label={'PCOS'}
                  isChecked={filterTags.pcos}
                  handleOnChange={() => handleSingleFilter('pcos')}
                />
                <CommunityCheckbox
                  label={'POI'}
                  isChecked={filterTags.poi}
                  handleOnChange={() => handleSingleFilter('poi')}
                />
              </div>
              <div className="flex flex-col">
                <CommunityCheckbox
                  label={'DOR/ Low AMH'}
                  isChecked={filterTags.dor}
                  handleOnChange={() => handleSingleFilter('dor')}
                />
                <CommunityCheckbox
                  label={'Hypothalamic dysfunction'}
                  isChecked={filterTags.hypothalamic}
                  handleOnChange={() => handleSingleFilter('hypothalamic')}
                />
              </div>
            </div>
            <div className="flex flex-row mt-2">
              <CommunityCheckbox
                label={'Tubal Blockage'}
                isChecked={filterTags.tubalBlockage}
                handleOnChange={() => handleSingleFilter('tubalBlockage')}
              />
            </div>
            <div className="flex flex-row">
              <CommunityCheckbox
                className={'mr-20'}
                label={'Endometriosis'}
                isChecked={filterTags.endometriosis}
                handleOnChange={() => handleSingleFilter('endometriosis')}
              />
              <CommunityCheckbox
                label={'Adenomyosis'}
                isChecked={filterTags.adenomyosis}
                handleOnChange={() => handleSingleFilter('adenomyosis')}
              />
            </div>
            <div className="flex flex-row mt-1">
              <CommunityCheckbox
                label={'Uterine/ Cervical Abnormalities'}
                isChecked={uterineGroup}
                handleOnChange={() => handleUterineGroup()}
              />
            </div>
            <div className="flex flex-row ml-5">
              <CommunityCheckbox
                className={'mr-[51px]'}
                label={'Uterine Fibroids'}
                isChecked={filterTags.uterineFibroids}
                handleOnChange={() => handleSingleFilter('uterineFibroids')}
              />
              <CommunityCheckbox
                label={'Polyps'}
                isChecked={filterTags.polyps}
                handleOnChange={() => handleSingleFilter('polyps')}
              />
            </div>
          </div>
        ) : null}
        <button className="flex flex-row mt-5 items-center" onClick={() => handleMaleDropdown()}>
          <div className="text-[14px] font-serif font-bold leading-[16px] mr-2">
            {maleDropdown ? '▼' : <img src={'/assets/dropdown_right.svg'} alt="Dropdown Right" />}
          </div>
          <div className="text-[14px] font-serif font-bold leading-[16px]">Male Factors</div>
        </button>
        {maleDropdown ? (
          <div>
            <div className="flex flex-row mt-3">
              <CommunityCheckbox
                label={'Sperm Abnormalities'}
                isChecked={spermGroup}
                handleOnChange={handleSpermGroup}
              />
            </div>
            <div className="flex flex-row ml-5">
              <div className="flex flex-col mr-14">
                <CommunityCheckbox
                  label={'Low Sperm Count'}
                  isChecked={filterTags.lowSperm}
                  handleOnChange={() => handleSingleFilter('lowSperm')}
                />
                <CommunityCheckbox
                  label={'Abnormal Morphology'}
                  isChecked={filterTags.abnormalMorphology}
                  handleOnChange={() => handleSingleFilter('abnormalMorphology')}
                />
              </div>
              <div className="flex flex-col">
                <CommunityCheckbox
                  label={'Poor Motility'}
                  isChecked={filterTags.poorMotility}
                  handleOnChange={() => handleSingleFilter('poorMotility')}
                />
              </div>
            </div>
            <CommunityCheckbox
              label={'Varicocele'}
              isChecked={filterTags.varicocele}
              handleOnChange={() => handleSingleFilter('varicocele')}
            />
            <CommunityCheckbox
              label={'Ejaculation Issues'}
              isChecked={filterTags.ejaculationIssues}
              handleOnChange={() => handleSingleFilter('ejaculationIssues')}
            />
            <CommunityCheckbox
              label={'Hypogonadism (Low T)'}
              isChecked={filterTags.hypogonadism}
              handleOnChange={() => handleSingleFilter('hypogonadism')}
            />
          </div>
        ) : null}
        <CommunitySubheader
          className={`mb-0 ${dropdownsOpen ? 'mt-4' : 'mt-16'}`}
          title={'By Success Method'}
        />
        <div className="mt-1">
          <CommunityCheckbox
            label={'Timed Intercourse'}
            isChecked={filterMethods.timed}
            handleOnChange={() => handleSingleMethod('timed')}
          />
          <CommunityCheckbox
            label={'IUI (Intrauterine Insemination)'}
            isChecked={filterMethods.iui}
            handleOnChange={() => handleSingleMethod('iui')}
          />
          <CommunityCheckbox
            label={'IVF (In Vitro Fertilization)'}
            isChecked={filterMethods.ivf}
            handleOnChange={() => handleSingleMethod('ivf')}
          />
          {/* <CommunityCheckbox
            label={'Donor Eggs/ Donor Sperm/ Surrogacy'}
            isChecked={true}
            handleOnChange={() => {}}
          /> */}
          <CommunityCheckbox
            label={'Egg/ Embryo Freezing'}
            isChecked={filterMethods.freezing}
            handleOnChange={() => handleSingleMethod('freezing')}
          />
        </div>
      </div>
      {ageError ? (
        <div className="ml-2 text-[12px] text-red-500 font-serif font-bold leading-[16px] mt-4">
          Min age must be less than max age
        </div>
      ) : null}
      <div className="flex flex-col justify-center mt-10">
        <CommunityButton onClick={() => handleSearch()}>Search</CommunityButton>
      </div>
    </div>
  );
};

export default CommunitySearch;
