import React from 'react';
import PostSubheader from './PostSubheader';
import PostTextbox from './PostTextbox';

const PostTimed = ({ post }) => {
  return (
    <div className="mt-8">
      {post.methodData?.triedPregnant?.length > 0 ? (
        <>
          <PostSubheader title={'Before this, I tried'} className="mb-2" />
          <PostTextbox text={post.methodData?.triedPregnant} className="mb-5" />{' '}
        </>
      ) : null}

      <PostSubheader
        title={`${[post.methodData?.cycles]} Timed Intercourse cycles before this`}
        className="mb-5"
      />
      {post.methodData?.successfulCase?.length > 0 ? (
        <>
          <PostSubheader title={'About this successful TI'} className="mb-2" />
          <PostTextbox text={post.methodData?.successfulCase} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.medicationTaken?.length > 0 ? (
        <>
          <PostSubheader title={'Medications Taken'} className="mb-2" />
          {post.methodData?.medicationTaken.map((medication) => {
            return (
              <PostTextbox
                text={
                  medication !== 'Other' ? medication : `Other: ${post.methodData.medicationOther}`
                }
                className="mb-1"
              />
            );
          })}
          <div className='mb-4'></div>
        </>
      ) : null}

      {post.methodData?.whyWorked?.length > 0 ? (
        <>
          <PostSubheader title={'Why I think this TI worked'} className="mb-2" />
          <PostTextbox text={post.methodData?.whyWorked} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.alternatives?.length > 0 ? (
        <>
          <PostSubheader title={'Supplements & Alternative Interventions'} className="mb-2" />
          <PostTextbox text={post.methodData?.alternatives} className="mb-5" />{' '}
        </>
      ) : null}
    </div>
  );
};

export default PostTimed;
