import React, { useContext, useState } from 'react';
import LearnList from '../components/learn/LearnList';
import { dashboardConfig } from '../config/dashboard.config';
import { SessionContext } from '../context/SessionContext';
import DashboardLayout from './DashboardLayout';

const LearnPage = () => {
  const { dashboardType } = useContext(SessionContext);
  const learnListData = dashboardConfig[dashboardType]?.cards;
  const [displayedContent, setDisplayedContent] = useState(dashboardConfig[dashboardType]);

  const dashboardList = ['curious', 'soon', 'trying', 'tryingLong', 'tryingLongRE'];

  const switchDashboardContent = (negative) => {
    const currDashboardIndex = dashboardList.indexOf(displayedContent.type);
    let newChecklistIndex;
    if (negative)
      newChecklistIndex = (currDashboardIndex - 1 + dashboardList.length) % dashboardList.length;
    else newChecklistIndex = (currDashboardIndex + 1 + dashboardList.length) % dashboardList.length;
    setDisplayedContent(dashboardConfig[dashboardList[newChecklistIndex]]);
  };

  return (
    <DashboardLayout currentPageIndex={3}>
      <div className="pb-5">
        <h2 className="text-0E0E0E font-serif text-2xl font-bold leading-7">Learn</h2>
        <div className="flex flex-row justify-between items-center mt-4"></div>
        <div className="flex flex-row justify-between items-center">
          <button
            className="font-serif font-bold text-lg text-center leading-7 ml-2"
            onClick={() => switchDashboardContent(true)}
          >
            {'<'}
          </button>
          <h2 className="font-serif font-bold text-xl text-center mx-auto mt-2 mb-2">
            {`“${displayedContent?.title}”`}
          </h2>
          <button
            className="font-serif font-bold text-lg text-center leading-7 ml-2"
            onClick={() => switchDashboardContent(false)}
          >
            {'>'}
          </button>
        </div>
        {learnListData ? <LearnList data={displayedContent.cards} /> : null}
      </div>
    </DashboardLayout>
  );
};

export default LearnPage;
