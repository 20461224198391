import React from 'react';

const PostSubheader = ({ title, className }) => {
  return (
    <div className={`text-[16px] font-serif font-bold leading-[16px] text-black italic ${className}`}>
      {title}
    </div>
  );
};

export default PostSubheader;
