export const fertilizationString = (id) => {
  switch (id) {
    case 'icsi':
      return 'ICSI';
    case 'ivf':
      return 'IVF';
    case 'no':
      return '';
    default:
      return '';
  }
};
