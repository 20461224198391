import { useContext, useEffect } from 'react';
import { ShareContext } from '../../context/ShareContext';
import CommunitySubheader from '../community/CommunitySubheader';
import ShareTextArea from './ShareTextArea';
import ShareQuestion from './ShareQuestion';
import CommunityIntegerInput from '../community/CommunityIntegerInput';
import ShareEggCard from './ShareEggCard';
import ShareTransferCard from './ShareTransferCard';
import Tooltip from '../ui/Tooltip';

const ShareIVF = ({}) => {
  const { methodData, setMethodData } = useContext(ShareContext);

  const handleData = (data, value) => {
    setMethodData((prevState) => ({
      ...prevState,
      [data]: value
    }));
  };

  useEffect(() => {
    const currentCount = methodData?.retrievals?.length || 0;
    if (methodData.retrievalCount > currentCount) {
      // If the retrievalCount has increased, add new default cards
      const newRetrievals = new Array(methodData.retrievalCount - currentCount).fill({
        date: new Date(),
        donorType: 'none',
        clinic: '',
        doctor: '',
        eggsRetrieved: 'N/A',
        eggsMature: 'N/A',
        eggsFertilized: 'N/A',
        day3Embryos: 'N/A',
        blastocysts: 'N/A',
        fertilization: 'no',
        pgt: 'na',
        normalEmbryos: 'N/A',
        stimulationProtocol: ''
      });

      setMethodData((prevState) => ({
        ...prevState,
        retrievals: [...prevState.retrievals, ...newRetrievals]
      }));
    } else if (methodData.retrievalCount < currentCount) {
      // If the retrievalCount has decreased, remove cards from the end
      setMethodData((prevState) => ({
        ...prevState,
        retrievals: prevState.retrievals.slice(0, methodData.retrievalCount)
      }));
    }
  }, [methodData.retrievalCount]);

  useEffect(() => {
    const currentTransferCount = methodData?.transfers?.length || 0;
    if (methodData.transferCount > currentTransferCount) {
      // If the transferCount has increased, add new default cards
      const newTransfers = new Array(methodData.transferCount - currentTransferCount).fill({
        date: new Date(),
        transferType: 'fresh',
        surrogate: false,
        embryosTransferred: '1',
        transferOutcome: 'Singleton Pregnancy',
        clinic: '',
        doctor: '',
        embryoAge: '',
        embryoGrade: ''
      });

      setMethodData((prevState) => ({
        ...prevState,
        transfers: [...prevState.transfers, ...newTransfers]
      }));
    } else if (methodData.transferCount < currentTransferCount) {
      // If the transferCount has decreased, remove cards from the end
      setMethodData((prevState) => ({
        ...prevState,
        transfers: prevState.transfers.slice(0, methodData.transferCount)
      }));
    }
  }, [methodData.transferCount]);

  const displayEggCards = () => {
    return methodData?.retrievals?.map((retrieval, i) => {
      return <ShareEggCard data={retrieval} key={i} index={i} />;
    });
  };

  const displayTransferCards = () => {
    return methodData?.transfers?.map((transfer, i) => {
      return <ShareTransferCard data={transfer} key={i} index={i} />;
    });
  };

  const handleIntegerInput = (data, event) => {
    const newValue = event.target.value;

    if (!event.target.validity.valid) {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: 0
      }));
    } else {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: Number(newValue)
      }));
    }
  };

  return (
    <div className="">
      <div>
        <CommunitySubheader className={'mt-5 mb-1 font-bold'} title={'IVF'} />
        <div className="text-[10px] text-footer-grey font-serif font-bold leading-[14px] mb-4">
          If you did egg retrieval and egg/ embryo freezing (no transfer), please select Egg/ Embryo
          Freezing.
        </div>
        <ShareQuestion
          text={`How long have you been trying to conceive? And what have you tried before this?`}
          className={'mb-3'}
        />
        <ShareTextArea
          text={methodData.triedPregnant}
          onChangeText={(e) => handleData('triedPregnant', e.target.value)}
        />
        <div className="flex flex-row my-3">
          <div className="flex flex-col">
            <ShareQuestion text={'How many egg retrievals have you'} />
            <ShareQuestion text={'done?'} />
          </div>
          <div className="ml-auto mr-10">
            <CommunityIntegerInput
              className={'w-[45px]'}
              value={methodData.retrievalCount}
              data={'retrievalCount'}
              onChange={handleIntegerInput}
            />
          </div>
        </div>

        {displayEggCards()}

        <div className="flex flex-row my-3 items-center">
          <ShareQuestion text={'How many transfers have you done?'} />
          <div className="ml-auto mr-10">
            <CommunityIntegerInput
              className={'w-[45px]'}
              value={methodData.transferCount}
              data={'transferCount'}
              onChange={handleIntegerInput}
            />
          </div>
        </div>

        {displayTransferCards()}

        <ShareQuestion
          text={`What did you do on your successful transfer?`}
          className={'mt-4 mb-2'}
        />
        <ShareTextArea
          text={methodData.successfulCase}
          onChangeText={(e) => handleData('successfulCase', e.target.value)}
        />

        <div className="flex flex-row">
          <ShareQuestion text={`Why do you think this transfer worked?`} className={'mb-2 mt-4'} />
          <Tooltip
            position={'left'}
            className={'ml-1'}
            textClass={'w-[200px] xs:w-[250px] sm:w-[350px]'}
            text={
              "We can't fully understand why things sometime work and they sometimes don't. But if you have any theories or hunches you'd like to share people may find your takes helpful. Feel free to omit if you don't have anything to add."
            }
          />
        </div>
        <ShareTextArea
          text={methodData.whyWorked}
          onChangeText={(e) => handleData('whyWorked', e.target.value)}
        />
        <ShareQuestion
          text={`If you or your partner used any alternative interventions 
          (supplements, acupuncture, diet changes, etc) 
          please share here.`}
          className={'mb-2 mt-2'}
        />
        <ShareTextArea
          text={methodData.alternatives}
          onChangeText={(e) => handleData('alternatives', e.target.value)}
        />
        <ShareQuestion text={`Can you share how much this IVF cost you?`} className={'mb-2'} />
        <ShareTextArea
          text={methodData.pricing}
          onChangeText={(e) => handleData('pricing', e.target.value)}
        />
        <ShareQuestion
          text={`Would you like to share anything else with people going through the fertility journey?`}
          className={'mb-2'}
        />
        <ShareTextArea
          text={methodData.advice}
          onChangeText={(e) => handleData('advice', e.target.value)}
        />
      </div>
    </div>
  );
};

export default ShareIVF;
