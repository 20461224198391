import React, { useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PostSearchCard from '../components/post/search/PostSearchCard';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PostSearchPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]); // assuming the response is an array of posts
  const [selectedResource, setSelectedResource] = useState(null);
  const [notFoundError, setNotFoundError] = useState(false);

  const query = useQuery();
  const tags = query.get('tags');
  const method = query.get('method');
  const minAge = query.get('minAge');
  const maxAge = query.get('maxAge');
  const race = query.get('race');

  useEffect(() => {
    const searchPosts = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/post/search`, {
          params: {
            tags,
            method,
            minAge,
            maxAge,
            race
          }
        });

        if (data) {
          setPosts(data);
        }
      } catch (err) {
        console.log(err);
        setNotFoundError(true);
      }
    };

    searchPosts();
  }, []);

  const handleBack = () => {
    navigate('/stories');
  };
  return (
    <DashboardLayout currentPageIndex={0}>
      <div className="pb-5">
        <div className="flex items-center mb-8 mt-2">
          <button className="focus:outline-none mb-1 mr-2" onClick={handleBack}>
            <img
              style={{ width: '30px', height: '30px' }}
              src={'/assets/arrow_back.svg'}
              alt={'arrow'}
            />
          </button>

          <h2 className="text-0E0E0E font-serif text-[20px] font-bold leading-7">
            Successful Fertility Stories
          </h2>
        </div>
        {posts.map((post, i) => {
          return <PostSearchCard key={i} post={post} />;
        })}
      </div>
    </DashboardLayout>
  );
};

export default PostSearchPage;
