import React from 'react';

const OnboardingProgressBar = ({ step }) => {
  const totalSteps = 4;
  const progress = Math.floor(step);
  const diamonds = Array.from({ length: totalSteps }, (_, i) => i);

  return (
    <div className="w-full h-4 bg-bar-grey bg-opacity-50 rounded-full flex items-center justify-between px-2 py-2">
      <div className="w-3 h-3"></div>
      {diamonds.map((_, index) => (
        <div
          key={index}
          className={`w-3 h-3 transform rotate-45 ${
            index < progress ? 'bg-dark-green' : 'bg-turquoise'
          } mr-2`}
        />
      ))}
    </div>
  );
};

export default OnboardingProgressBar;
