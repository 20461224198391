import React from 'react';
import PostSubheader from './PostSubheader';
import PostTextbox from './PostTextbox';
import PostTag from './PostTag';

const PostIUI = ({ post }) => {
  return (
    <div className="mt-8">
      {post.methodData?.triedPregnant?.length > 0 ? (
        <>
          <PostSubheader title={'Before this, I tried'} className="mb-2" />
          <PostTextbox text={post.methodData?.triedPregnant} className="mb-5" />{' '}
        </>
      ) : null}

      <PostSubheader
        title={`${[post.methodData?.cycles]} IUI cycles before this`}
        className="mb-5"
      />
      {post.methodData?.successfulCase?.length > 0 ? (
        <>
          <PostSubheader title={'About this successful IUI'} className="mb-2" />
          <PostTextbox text={post.methodData?.successfulCase} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.pricing?.length > 0 ? (
        <>
          <PostSubheader title={'Cost information'} className="mb-2" />
          <PostTextbox text={post.methodData?.pricing} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.medicationTaken?.length > 0 ? (
        <>
          <PostSubheader title={'Medications Taken'} className="mb-2" />
          <PostTextbox text={post.methodData?.medicationTaken.toString()} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.donorSperm > 0 ? (
        <>
          <PostSubheader title={'With Donor Sperm'} className="mb-5" />
        </>
      ) : null}

      {post.methodData?.whyWorked?.length > 0 ? (
        <>
          <PostSubheader title={'Why I think this TI worked'} className="mb-2" />
          <PostTextbox text={post.methodData?.whyWorked} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.alternatives?.length > 0 ? (
        <>
          <PostSubheader title={'Supplements & Alternative Interventions'} className="mb-2" />
          <PostTextbox text={post.methodData?.alternatives} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.advice?.length > 0 ? (
        <>
          <PostSubheader title={'My Advice'} className="mb-2" />
          <PostTextbox text={post.methodData?.advice} className="mb-5" />{' '}
        </>
      ) : null}
    </div>
  );
};

export default PostIUI;
