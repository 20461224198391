import React from 'react';

const Question = ({ children }) => {
  return (
    <h2
      style={{ height: '120px' }}
      className="font-sans text-xl font-normal leading-10 flex items-center my-5"
    >
      {children}
    </h2>
  );
};

export default Question;
