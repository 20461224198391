import React from 'react';
import { useContext } from 'react';
import { SessionContext } from '../../context/SessionContext';
import OnboardingProgressBar from './OnboardingProgressBar';

const OnboardingHeader = ({ title, progress }) => {
  const { currentStep, setCurrentStep, userPath, popOffUserPath } = useContext(SessionContext);

  const handleBack = () => {
    // pop step off of user history
    const prevStep = popOffUserPath()?.step;
    prevStep && userPath.length > 0 ? setCurrentStep(prevStep) : setCurrentStep(0);
  };
  return (
    <div>
      <header className="flex items-center justify-center relative mt-9 mb-4 py-5 px-6">
        <button onClick={handleBack} className="absolute left-6 top-1/2 -translate-y-1/2 transform">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
          >
            <path
              d="M16.5 8H1.5M1.5 8L8.25 14.75M1.5 8L8.25 1.25"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h1 className="font-sans text-font-grey text-md font-bold">{title}</h1>
      </header>
      {/* <div className="w-full h-4 bg-gray-300 rounded-full">
        <div className="h-full bg-blue-500 rounded-full" style={{ width: `${progress}%` }}></div>
      </div> */}
      <OnboardingProgressBar step={currentStep} />
    </div>
  );
};

export default OnboardingHeader;
