import React from 'react';
import { donorString } from '../../utils/DonorString';
import { fertilizationString } from '../../utils/FertilizationString';
import { pgtString } from '../../utils/PgtString';

const PostRetrievalCard = ({ retrieval, index }) => {
  const dateStr = retrieval?.date;

  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

  return (
    <div className={`bg-card-green flex flex-col px-3 py-2 justify-center my-5`}>
      <div className="grid grid-cols-3 gap-2 items-center">
        <div className="font-bold font-serif text-[12px] leading-[24px] text-right w-[75px]">{`Retrieval ${
          index + 1
        }`}</div>
        <div className="font-serif text-[12px] leading-[24px]">{formattedDate}</div>
        <div className="font-serif text-[12px] leading-[24px]">
          {donorString(retrieval.donorType)}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2 items-center">
        <div className="font-bold font-serif text-[12px] leading-[24px] text-right w-[75px]">
          At
        </div>
        <div className="font-serif text-[12px] leading-[24px]">{retrieval.clinic}</div>
        <div className="font-serif text-[12px] leading-[24px]">{retrieval.doctor}</div>
      </div>
      <div className="grid grid-cols-5 gap-2 items-end mt-4">
        <div className={'flex flex-col'}>
          <div className={'font-bold font-serif text-[10px]'}>Eggs Retrieved</div>
          <div className={'font-bold font-serif text-[10px] mt-2'}>{retrieval.eggsRetrieved}</div>
        </div>
        <div className={'flex flex-col'}>
          <div className={'font-bold font-serif text-[10px]'}>Mature Eggs</div>
          <div className={'font-bold font-serif text-[10px] mt-2'}>{retrieval.eggsMature}</div>
        </div>
        <div className={'flex flex-col'}>
          <div className={'font-bold font-serif text-[10px]'}>Fertilized Eggs</div>
          <div className={'font-bold font-serif text-[10px] mt-2'}>{retrieval.eggsFertilized}</div>
        </div>
        <div className={'flex flex-col'}>
          <div className={'font-bold font-serif text-[10px]'}>Day 3 Embryos</div>
          <div className={'font-bold font-serif text-[10px] mt-2'}>{retrieval.day3Embryos}</div>
        </div>
        <div className={'flex flex-col'}>
          <div className={'font-bold font-serif text-[10px]'}>Blastocysts</div>
          <div className={'font-bold font-serif text-[10px] mt-2'}>{retrieval.blastocysts}</div>
        </div>
      </div>

      {retrieval?.fertilization && retrieval?.fertilization !== 'no' ? (
        <div className={'font-bold font-serif text-[10px] mt-2'}>
          {fertilizationString(retrieval.fertilization)} Fertilization
        </div>
      ) : null}
      <div className="grid grid-cols-2 gap-2 items-center mt-2">
        <div className={'font-bold font-serif text-[10px]'}>
          {pgtString(retrieval.pgt)} {retrieval.pgt !== 'na' && 'Tested'}
        </div>
        <div className="font-bold font-serif text-[9px]">
          Genetically "Normal" embryos: {retrieval.normalEmbryos}
        </div>
      </div>
      <div className="font-bold font-serif text-[12px] mt-4">Stim Protocol</div>
      <div className="font-serif text-[12px]">{retrieval.stimulationProtocol}</div>
    </div>
  );
};

export default PostRetrievalCard;
