import React from 'react';

const CommunityIntegerInput = ({ className, value, data, onChange }) => {
  return (
    <input
      className={`border border-black bg-transparent rounded-lg pl-2 py-1 focus:outline-none ${className}`}
      min="0"
      type="number"
      value={value}
      onInput={(e) => onChange(data, e)}
      onChange={(e) => onChange(data, e)}
    />
  );
};

export default CommunityIntegerInput;
