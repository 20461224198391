import React from 'react';

const CommunitySubheader = ({ title, className }) => {
  return (
    <div className={`text-[14px] font-serif leading-[16px] text-dark-green ${className}`}>
      {title}
    </div>
  );
};

export default CommunitySubheader;
