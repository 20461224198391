import React, { useContext, useEffect, useState } from 'react';
import OnboardingButton from './OnboardingButton';
import { SessionContext } from '../../context/SessionContext';
import GoogleButton from 'react-google-button';

const OnboardingFinish = ({ handleFinish, handleError, handleGoogleSignup }) => {
  const { user, isLoading } = useContext(SessionContext);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (!user && !isLoading) {
      setLoggedIn(false);
    } else if (!user && isLoading) {
    } else setLoggedIn(false);
  }, [user, isLoading]);

  return (
    <div className="bg-white w-full h-screen flex flex-col pt-4 tall:pt-20 pb-8">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-2">
          Your Fertility Plan is
          <br />
          Almost Here
        </p>
        <p className="font-serif text-left leading-7 text-lg mb-14">
          Save it so you can access it later.
        </p>
      </div>
      {!isLoggedIn ? (
        <div>
          <GoogleButton
            style={{ width: 'auto', fontSize: '22px' }}
            type="light"
            className="mb-3 font-sans rounded-md outline-none font-semibold"
            label="Sign in with Google"
            onClick={() => {
              handleGoogleSignup();
            }}
          />
          <OnboardingButton onClick={() => handleFinish('local')}>
            Sign Up to See Your Plan
          </OnboardingButton>
        </div>
      ) : (
        <OnboardingButton onClick={() => handleFinish('google')}>See My Plan</OnboardingButton>
      )}
      <div className="text-red">{handleError ? `Failed to signin with Google` : ''}</div>
      <img src="/assets/onboarding2.svg" alt="" className="h-1/3 w-auto mx-auto mt-12" />
    </div>
  );
};

export default OnboardingFinish;
