import React from 'react';

const PostTag = ({ tag }) => {
  const colorVariants = {
    male: 'bg-male-tag p-1 rounded',
    female: 'bg-female-tag p-1 rounded',
    other: 'bg-other-tag p-1 rounded'
  };

  return <div className={colorVariants[tag.factor]}>{tag.name}</div>;
};

export default PostTag;
