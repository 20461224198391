import React from 'react';
import OnboardingHeader from './OnboardingHeader';
import Question from './Question';
import AnswerOption from './answer/AnswerOption';
import AnswerBirthday from './answer/AnswerBirthday';
import AnswerAge from './answer/AnswerAge';

const OnboardingCard = ({ progress, handleStep, content }) => {
  const renderAnswer = () => {
    switch (content.answerType) {
      case 'age':
        return (
          <AnswerAge handleStep={handleStep} answer={content.answers[0]} step={content.stepValue} />
        );
      case 'birthday':
        return (
          <AnswerBirthday
            handleStep={handleStep}
            answer={content.answers[0]}
            step={content.stepValue}
          />
        );
      default:
        return (
          <ul>
            {content.answers?.map((answer, i) => {
              return (
                <AnswerOption
                  key={i}
                  handleStep={handleStep}
                  answer={answer}
                  step={content.stepValue}
                >
                  {answer?.value}
                </AnswerOption>
              );
            })}
          </ul>
        );
    }
  };

  return (
    <div className="h-screen bg-white flex flex-col">
      <OnboardingHeader title={'Demographic Profile'} progress={progress} />
      <Question>{content.question}</Question>
      {renderAnswer()}
      <div className="flex-grow"></div>
      {content.additional ? <p className="text-xs mb-24">{content.additional}</p> : null}
    </div>
  );
};

export default OnboardingCard;
