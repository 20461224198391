import React, { useContext } from 'react';
import OnboardingButton from './OnboardingButton';
import { SessionContext } from '../../context/SessionContext';
import OnboardingLoginButton from './OnboardingLoginButton';
import axios from 'axios';

const OnboardingStart = ({ setSkipStep }) => {
  const { setCurrentStep, setUserPath } = useContext(SessionContext);

  const handleStart = () => {
    setSkipStep(false);
    setCurrentStep(1);
    setUserPath([]);
  };

  const handleLogin = () => {
    setSkipStep(true);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        withCredentials: true
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="bg-white w-full h-full flex flex-col justify-between">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-5">
          Fertility plan
          <br />
          Just for you
          <br />
          With you in control
        </p>
        <p className="font-sans text-left leading-6 text-lg my-5">
          Take our 6 question quiz and we’ll provide you with a free personalized plan for your
          fertility journey based on your goals.
          <br /> <br /> Vetted advice from fertility practitioners based on their expertise.{' '}
        </p>
        <img src="/assets/onboarding1.svg" alt="" className="w-4/5 h-auto mx-auto" />
      </div>
      <div className="flex flex-col justify-center mt-auto">
        <OnboardingButton onClick={handleStart}>Start</OnboardingButton>
        <OnboardingLoginButton onClick={handleLogin}>Log Back In</OnboardingLoginButton>
        {/* <OnboardingLoginButton onClick={handleLogout}>Log Out</OnboardingLoginButton> */}
      </div>
    </div>
  );
};

export default OnboardingStart;
