import React from 'react';
import { transferTypeString } from '../../utils/TransferTypeString';

const PostTransferCard = ({ transfer, index }) => {
  const dateStr = transfer?.date;

  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

  return (
    <div className={`bg-card-purple flex flex-col px-3 py-2 justify-center my-5`}>
      <div className="grid grid-cols-3 gap-2 items-center">
        <div className="font-bold font-serif text-[12px] leading-[24px] text-right w-[75px]">{`Transfer ${
          index + 1
        }`}</div>
        <div className="font-serif text-[12px] leading-[24px]">{formattedDate}</div>
      </div>
      <div className="grid grid-cols-3 gap-2 items-center">
        <div className="font-bold font-serif text-[12px] leading-[24px] text-right w-[75px]">
          At
        </div>
        <div className="font-serif text-[11px] leading-[24px]">{transfer.clinic}</div>
        <div className="font-serif text-[11px] leading-[24px]">{transfer.doctor}</div>
      </div>

      <div className="grid grid-flow-col gap-2 items-center mt-3">
        <div className="flex flex-row">
          <div className="font-bold font-serif text-[12px] leading-[24px] ml-3 whitespace-nowrap">
            {transfer.embryosTransferred} {transferTypeString(transfer.transferType)} Embryo
            Transferred
          </div>
          <div className="ml-1 font-bold font-serif text-[12px] leading-[24px] whitespace-nowrap">
            {transfer?.surrogate ? 'To A Surrogate' : ''}
          </div>
        </div>
        <div className="font-bold font-serif text-[12px] leading-[24px] ml-3 whitespace-nowrap">
          {transfer.embryoAge} Embryo Age
        </div>
        <div className="font-bold font-serif text-[12px] leading-[24px] whitespace-nowrap">
          {transfer?.embryoGrade} Embryo Grade
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="font-bold font-serif text-[12px] leading-[24px] ml-3 whitespace-nowrap">
          Transfer Outcome:
        </div>
        <div className="font-serif text-[12px] leading-[24px] ml-3 whitespace-nowrap">
          {transfer.transferOutcome}
        </div>
      </div>
    </div>
  );
};

export default PostTransferCard;
