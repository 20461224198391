export const pgtString = (id) => {
  switch (id) {
    case 'pgta':
      return 'PGT-A (PGS)';
    case 'pgtm':
      return 'PGT-M (PGD)';
    case 'na':
      return 'Not Applicable';
    default:
      return '';
  }
};
