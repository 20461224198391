import React from 'react';
import { Link } from 'react-router-dom'; 

const CommunityCard = ({ card, color }) => {
  const title = card?.title;
  const filter = card?.filter;
  const fontSize = card?.fontSize;
  const colorVariants = {
    yellow: 'w-full bg-yellow rounded-sm my-3 flex items-center pl-10',
    green: 'w-full bg-lighter-green rounded-sm my-3 flex items-center pl-10'
  };
  return (
    <Link to={`/search?${filter}`}>
      <button style={{ height: '47px' }} className={colorVariants[color]}>
        <h2 className={`text-black font-serif leading-[30px] text-[20px]`}>{title}</h2>
      </button>
    </Link>
  );
};

export default CommunityCard;