import React from 'react';
import Checkbox from '../ui/Checkbox';

const ChecklistCard = ({ content, handleCheckBox, checkId, checkedBoxes }) => {
  const handleClick = (isChecked) => {
    handleCheckBox(checkId, !isChecked);
  };

  return (
    <>
      <div className="bg-yellow w-full flex px-5 py-5 mb-5">
        <div className="mr-2">
          <Checkbox
            isChecked={checkedBoxes.includes(checkId)}
            handleOnChange={handleClick}
            checkboxSize="20px"
            iconSize="18px"
            bgColor={'yellow'}
          />
        </div>
        <p className="mt-1 font-serif font-bold text-[12px] leading[20px]">{content}</p>
      </div>
    </>
  );
};

export default ChecklistCard;
