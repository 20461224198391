import { useContext, useEffect, useState } from 'react';
import { ShareContext } from '../../context/ShareContext';
import CommunitySubheader from '../community/CommunitySubheader';
import ShareTextArea from './ShareTextArea';
import ShareQuestion from './ShareQuestion';
import CommunityIntegerInput from '../community/CommunityIntegerInput';
import ShareEggCard from './ShareEggCard';

const ShareFreezing = ({}) => {
  const { methodData, setMethodData } = useContext(ShareContext);

  useEffect(() => {
    const currentCount = methodData?.retrievals?.length || 0;
    if (methodData.retrievalCount > currentCount) {
      // If the retrievalCount has increased, add new default cards
      const newRetrievals = new Array(methodData.retrievalCount - currentCount).fill({
        date: new Date(),
        donorType: 'none',
        clinic: '',
        doctor: '',
        eggsRetrieved: 'N/A',
        eggsMature: 'N/A',
        eggsFertilized: 'N/A',
        day3Embryos: 'N/A',
        blastocysts: 'N/A',
        fertilization: 'no',
        pgt: 'na',
        normalEmbryos: 'N/A',
        stimulationProtocol: ''
      });

      setMethodData((prevState) => ({
        ...prevState,
        retrievals: [...prevState.retrievals, ...newRetrievals]
      }));
    } else if (methodData.retrievalCount < currentCount) {
      // If the retrievalCount has decreased, remove cards from the end
      setMethodData((prevState) => ({
        ...prevState,
        retrievals: prevState.retrievals.slice(0, methodData.retrievalCount)
      }));
    }
  }, [methodData.retrievalCount]);

  const handleData = (data, value) => {
    setMethodData((prevState) => ({
      ...prevState,
      [data]: value
    }));
  };

  const displayEggCards = () => {
    return methodData?.retrievals?.map((retrieval, i) => {
      return <ShareEggCard data={retrieval} key={i} index={i} />;
    });
  };

  const handleIntegerInput = (data, event) => {
    const newValue = event.target.value;

    if (!event.target.validity.valid) {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: 0
      }));
    } else {
      setMethodData((prevState) => ({
        ...prevState,
        [data]: newValue
      }));
    }
  };

  return (
    <div className="">
      <div>
        <CommunitySubheader className={'mt-5 mb-1 font-bold'} title={'Egg Freezing'} />
        <div className="text-[10px] text-footer-grey font-serif font-bold leading-[14px] mb-4">
          If you did an embryo transfer as well, please select IVF.
        </div>
        <ShareQuestion
          text={`Why did you choose to do egg/ embryo freezing?`}
          className={'mt-4 mb-2'}
        />
        <ShareTextArea
          text={methodData.whyWorked}
          onChangeText={(e) => handleData('whyWorked', e.target.value)}
        />
        <div className="flex flex-row my-3">
          <div className="flex flex-col">
            <ShareQuestion text={'How many egg retrievals have you'} />
            <ShareQuestion text={'done?'} />
          </div>
          <div className="ml-auto mr-10">
            <CommunityIntegerInput
              className={'w-[45px]'}
              value={methodData.retrievalCount}
              data={'retrievalCount'}
              onChange={handleIntegerInput}
            />
          </div>
        </div>

        {displayEggCards()}

        <ShareQuestion
          text={`What did you do on your successful egg/ embryo freezing?`}
          className={'mt-4 mb-2'}
        />
        <ShareTextArea
          text={methodData.successfulCase}
          onChangeText={(e) => handleData('successfulCase', e.target.value)}
        />
        <ShareQuestion
          text={`Can you share how much this egg/embryo freezing cost you?`}
          className={'mb-2'}
        />
        <ShareTextArea
          text={methodData.pricing}
          onChangeText={(e) => handleData('pricing', e.target.value)}
        />
        <ShareQuestion
          text={`Do you have any advice for other people going through egg/embryo freezing?`}
          className={'mb-2 mt-2'}
        />
        <ShareTextArea
          text={methodData.advice}
          onChangeText={(e) => handleData('advice', e.target.value)}
        />
      </div>
    </div>
  );
};

export default ShareFreezing;
