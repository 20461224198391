import React from 'react';
import PostSubheader from './PostSubheader';
import PostTextbox from './PostTextbox';
import PostTag from './PostTag';
import PostRetrievalCard from './PostRetrievalCard';

const PostFreezing = ({ post }) => {
  return (
    <div className="mt-8">
      <PostSubheader
        title={`${post.methodData?.retrievalCount} Egg Retrieval${
          post.methodData?.retrievalCount > 1 ? 's' : ''
        }`}
        className="mb-5"
      />

      {post.methodData?.retrievals.map((retrieval, i) => {
        return <PostRetrievalCard retrieval={retrieval} index={i} key={i} />;
      })}
      {post.methodData?.whyWorked?.length > 0 ? (
        <>
          <PostSubheader title={'About my decision to do Egg/ Embryo Freezing'} className="mb-2" />
          <PostTextbox text={post.methodData?.whyWorked} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.successfulCase?.length > 0 ? (
        <>
          <PostSubheader title={'About this successful Egg/ Embryo Freezing'} className="mb-2" />
          <PostTextbox text={post.methodData?.successfulCase} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.pricing?.length > 0 ? (
        <>
          <PostSubheader title={'Cost information'} className="mb-2" />
          <PostTextbox text={post.methodData?.pricing} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.advice?.length > 0 ? (
        <>
          <PostSubheader title={'My Advice'} className="mb-2" />
          <PostTextbox text={post.methodData?.advice} className="mb-5" />{' '}
        </>
      ) : null}
    </div>
  );
};

export default PostFreezing;
