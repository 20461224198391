import React from 'react';

const ResourceCard = ({ card, setSelectedResource }) => {
  const title = card?.title;
  const fontSize = card?.fontSize;
  return (
    <button
      onClick={() => setSelectedResource(card)}
      style={{ height: '76px' }}
      className="w-full bg-button-grey rounded-sm my-5 flex items-center pl-10"
    >
      <h2 className={`text-black font-serif font-bold leading-7  text-2xl ${fontSize} xs:text-2xl`}>
        {title}
      </h2>
    </button>
  );
};

export default ResourceCard;
