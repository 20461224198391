import React from 'react';

const ShareTextArea = ({ text, onChangeText }) => {
  return (
    <div className="w-full">
      <textarea
        className="w-full rounded-lg p-2 border-[1px] border-black focus:outline-none"
        value={text}
        rows={3}
        onChange={onChangeText}
      />
    </div>
  );
};

export default ShareTextArea;
