// import React from 'react';
// import { Link, NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  //const location = useLocation();
  // return (
  //   <header>
  //     <nav className="flex justify-center">
  //       <ul className="flex space-x-4">
  //         <li>
  //           <NavLink exact to="/" activeClassName="active">
  //             Home
  //           </NavLink>
  //         </li>
  //         <li>
  //           <NavLink to="/onboarding" activeClassName="active">
  //             Onboarding
  //           </NavLink>
  //         </li>
  //       </ul>
  //     </nav>
  //   </header>
  // );
};

export default Header;
