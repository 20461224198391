import React from 'react';
import LearnCard from './LearnCard';

const LearnList = ({ data }) => {
  return (
    <div className="mx-3">
      {data.map((card, i) => (
        <LearnCard key={i} card={card} color={i % 2 === 0 ? 'green' : 'yellow'} />
      ))}
    </div>
  );
};

export default LearnList;
