/* STEP RULES
 * On start, step = 1
 * To take a half step, specify the step value to Integer(currentStep) + answer.step
 * ie. To go from step 3.1 -> 3.2, enter a step of 0.2, for Int(3.1) + 0.2 -> 3.2
 * To finish, enter step = -1
 */

export const onboardingConfig = {
  steps: [
    {
      stepValue: 1,
      question: "What's your birthday?",
      answerType: 'birthday',
      answers: [{ value: '', step: 1, id: '0' }]
    },
    {
      stepValue: 2,
      question: 'What’s your gender (assigned at birth)?',
      answerType: 'option',
      answers: [
        { value: 'Female', step: 1, id: '1' },
        { value: 'Male', step: 1, id: '2' }
      ],
      additional:
        'Unfortunately, due to limited resources, the current version of our fertility plan targets heterosexual couples and does not fully address the needs of LGBTQIA2S+ couples and individuals. We are committed to making an inclusive product and are actively working to expand our product to cater to all individuals in future updates.'
    },
    {
      stepValue: 3,
      question: 'Do you have a partner?',
      answerType: 'option',
      answers: [
        { value: 'Yes', step: 0.1, id: '3' },
        { value: 'No', step: 1, id: '4' }
      ]
    },
    {
      stepValue: 3.1,
      question: "What's your partner's birthday?",
      answerType: 'birthday',
      answers: [{ value: '', step: 0.2, id: '5' }]
    },
    {
      stepValue: 3.2,
      question: 'What’s your partner’s gender assigned at birth?',
      answerType: 'option',
      answers: [
        { value: 'Female', step: 1, id: '6' },
        { value: 'Male', step: 1, id: '7' }
      ]
    },
    {
      stepValue: 4,
      question: 'Are you trying to get pregnant?',
      answerType: 'option',
      answers: [
        { value: "Nope, but I'm curious about fertility", step: -1, id: '8' },
        { value: "Not yet, I'll start trying soon", step: -1, id: '9' },
        { value: "Yes, I've been trying for 1-6 months", step: -1, id: '10' },
        { value: "Yes, I've been trying for over 6 months", step: 0.1, id: '11' }
      ]
    },
    {
      stepValue: 4.1,
      question: 'Have you seen a doctor',
      answerType: 'option',
      answers: [
        { value: 'Not yet', step: -1, id: '13' },
        {
          value: "Yes, I've met my PCP (Primary care Physician) or Gynecologist",
          step: -1,
          id: '14'
        },
        {
          value: "Yes, I've met a RE (Reproductive Endocrinologist) or fertility doctor",
          step: -1,
          id: '15'
        }
      ]
    }
  ]
};
