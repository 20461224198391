import { useEffect, useContext } from 'react';
import { SessionContext } from '../../context/SessionContext';
import axios from 'axios';

export const FrontChat = () => {


  // TODO: add user to session context
  const { user } = useContext(SessionContext);

  const getChatHash = async () => {
    if (user) {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/getChatHash`, {
        withCredentials: true
      });
      return data;
    }
  };

  const initFrontChat = async () => {
    const hash = await getChatHash();
    window.FrontChat('init', {
      chatId: '1ff7a7d9f3a9bc811f549cbe24859b5a',
      useDefaultLauncher: true,
      email: user?.email,
      userHash: hash
    });
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    script.async = true;
    script.onload = initFrontChat;

    document.body.appendChild(script);

    // remove chat widget when component unmounts
    return () => {
      if (window.FrontChat) {
        window.FrontChat('shutdown');
      }

      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};
