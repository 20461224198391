import { useContext, useState } from 'react';
import OnboardingButton from './OnboardingButton';
import { SessionContext } from '../../context/SessionContext';
import { Link } from 'react-router-dom';

const OnboardingSignup = ({ handleSignup }) => {
  const { setCurrentStep, userPath, setUserPath, dashboardType, setDashboardType } =
    useContext(SessionContext);

  const birthdayPath = userPath.find((item) => item.step === 1).answer;
  const sexPath = userPath.find((item) => item.step === 2).answer;
  const havePartner = userPath.find((item) => item.step === 3).answer;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [username, setUsername] = useState('');
  const [sex, setSex] = useState(sexPath);
  const [birthday, setBirthday] = useState(birthdayPath);
  const [hasPartner, setHasPartner] = useState(havePartner === 'Yes');
  const [partnerBirthday, setPartnerBirthday] = useState('');
  const [partnerSex, setPartnerSex] = useState('');
  const [conditions, setConditions] = useState([]);

  useState(() => {
    if (havePartner === 'Yes') {
      const partnerBirthday = userPath.find((item) => item.step === 3.1).answer;
      setPartnerBirthday(partnerBirthday);
      const partnerSex = userPath.find((item) => item.step === 3.2).answer;
      setPartnerSex(partnerSex);
    }
  }, []);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    // const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    // return re.test(password);
    return true;
  };

  const validateUsername = (username) => {
    const re = /^\S{3,}$/;
    return re.test(username);
  };

  const validateDate = (date) => {
    // check age requirement maybe
    return true;
  };

  const handleBack = (e) => {
    setCurrentStep(0);
  };

  const addCondition = (name, factor) => {
    setConditions([...conditions, { name: name, factor: factor }]);
  };

  const removeCondition = (name, factor) => {
    setConditions(
      conditions.filter((condition) => condition.name !== name || condition.factor !== factor)
    );
  };

  const handleCheckboxChange = (name, factor, isChecked) => {
    if (isChecked) {
      addCondition(name, factor);
    } else {
      removeCondition(name, factor);
    }
  };

  const enableSignup = () => {
    // Check if all inputs are filled correctly
    if (!validateEmail(email)) return false;
    if (!validatePassword(password)) return false;
    if (!validateUsername(username)) return false;
    if (!validateDate(birthday)) return false;
    if (hasPartner && (!validateDate(partnerBirthday) || partnerSex === '')) return false;

    return true;
  };

  const handleSignupData = () => {
    const body = {
      username: username,
      password: password,
      email: email,
      userData: {
        birthday: birthday,
        gender: sex,
        havePartner: hasPartner,
        conditions: conditions
      },
      journey: userPath,
      dashboardType: dashboardType
    };

    if (hasPartner) {
      body.userData.partnerBirthday = partnerBirthday;
      body.userData.partnerGender = partnerSex;
    }

    handleSignup(body);
  };

  return (
    <div className="bg-white w-full h-screen flex flex-col pt-8 pb-8">
      <div className="flex flex-col justify-center ">
        <p className="font-serif font-bold text-2xl text-left leading-8 my-2 mb-5 ml-3">Sign Up</p>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="mb-2">
            <div className="text-lg font-bold">email</div>
            <div className="text-[12px] text-footer-grey">*We will never share this publicly</div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateEmail(email) || email === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">password</div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validatePassword(password) || password === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">password confirmation</div>
            <input
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                password === passwordConfirm || passwordConfirm === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">username</div>
            <div className="text-[12px] text-footer-grey">
              *Only your username will be associated with your posts. You can make your username
              unidentifiable to remain anonymous.{' '}
            </div>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateUsername(username) || username === '' ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">birthday</div>
            <div className="text-[12px] text-footer-grey">*We will never share this publicly</div>
            <input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                validateDate(birthday) ? '' : 'border-red-500'
              }`}
            />
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">sex assigned at birth</div>
            <select
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="mb-2">
            <div className="text-lg font-bold">have a partner</div>
            <select
              value={hasPartner}
              onChange={(e) => {
                setHasPartner(e.target.value === 'true');
              }}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {hasPartner ? (
            <div>
              <div className="mb-2">
                <div className="text-lg font-bold">partner's birthday</div>
                <input
                  type="date"
                  value={partnerBirthday}
                  onChange={(e) => setPartnerBirthday(e.target.value)}
                  className={`w-[50%] text-[16px] border-b-2 border-black focus:outline-none ${
                    validateDate(partnerBirthday) || partnerBirthday === '' ? '' : 'border-red-500'
                  }`}
                />
              </div>
              <div className="mb-2">
                <div className="text-lg font-bold">partner's sex assigned at birth</div>
                <select
                  value={partnerSex}
                  onChange={(e) => setPartnerSex(e.target.value)}
                  className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          ) : null}
          <div className="mb-2">
            <div className="text-lg font-bold">status</div>
            <select
              value={dashboardType}
              onChange={(e) => {
                setDashboardType(e.target.value);
              }}
              className="w-[50%] text-[16px] border-b-2 border-black focus:outline-none"
            >
              <option value="curious">Just Curious</option>
              <option value="soon">Trying Soon</option>
              <option value="trying">Started Trying</option>
              <option value="tryingRE">Been Trying</option>
              <option value="tryingRELong">Seen a Doctor</option>
            </select>
          </div>
        </div>
        <div className="mt-4 text-[10px] text-font-grey focus:outline-none">
          By signing up you agree to The Fertility Plan’s{' '}
          <Link to="/terms" className="underline hover:text-blue-500">
            Terms
          </Link>{' '}
          and acknowledge reviewing our{' '}
          <Link to="/privacy" className="underline hover:text-blue-500">
            Privacy Policy
          </Link>
          .
        </div>
        <div className="mt-4">
          <OnboardingButton disabled={!enableSignup()} onClick={() => handleSignupData()}>
            Sign Up to See Your Plan
          </OnboardingButton>
        </div>
      </div>
      {/* <div className="text-[20px] font-serif font-bold leading-[30px] mt-5 mb-4">
        Any known conditions that impact you? (Optional)
      </div>
      <div className="flex flex-row w-full pl-2 pr-8">
        <div className="flex flex-col mr-16">
          <div className="text-sm font-serif font-bold leading-[16px] mb-2">Female Factors</div>
          <div className="flex flex-row">
            <input
              type="checkbox"
              id="endometriosisFemale"
              defaultChecked={false}
              onChange={(e) => handleCheckboxChange('Endometriosis', 'Female', e.target.checked)}
            />
            <label
              className="text-[12px] font-serif font-bold leading-[16px] ml-2"
              htmlFor="femaleFactors"
            >
              Endometriosis
            </label>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-sm font-serif font-bold leading-[16px] mb-2">Male Factors</div>
          <div className="flex flex-row">
            <input
              type="checkbox"
              id="endometriosisMale"
              defaultChecked={false}
              onChange={(e) => handleCheckboxChange('Endometriosis', 'Male', e.target.checked)}
            />
            <label
              className="text-[12px] font-serif font-bold leading-[16px] ml-2"
              htmlFor="femaleFactors"
            >
              Endometriosis
            </label>
          </div>
        </div> 
      </div>*/}
    </div>
  );
};

export default OnboardingSignup;
