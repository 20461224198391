import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';

export const SessionProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  // State to track current step of onboarding
  const [currentStep, setCurrentStep] = useState(localStorage.getItem('currentStep') || 0);
  // Store user path as an array of values
  const [userPath, setUserPath] = useState(localStorage.getItem('userPath') || []);

  // Store user's current dashboard setting
  const [dashboardType, setDashboardType] = useState(localStorage.getItem('dashboardType') || '');
  const [localDashboard, setLocalDashboard] = useState(
    localStorage.getItem('localDashboard') || ''
  );
  const [localChecklist, setLocalChecklist] = useState(
    localStorage.getItem('localChecklist') || []
  );

  const [user, setUser] = useState(null);

  useEffect(() => {
    const authLogic = async () => {
      setLoading(true);
      if (!user) {
        const authUser = await fetchUser();

        setUser(authUser);
        setLoading(false);
      }
    };
    authLogic();
  }, []);

  if (currentStep === 0) {
    console.log('Onboarding step has been reset: Step ', currentStep);
  }

  useEffect(() => {
    // Sets local storage whenever there is a state change
    localStorage.setItem('dashboardType', dashboardType);
    // console.log('localStore dashboardType: ', dashboardType);
  }, [dashboardType]);

  useEffect(() => {
    // Sets local storage whenever there is a state change
    localStorage.setItem('localDashboard', localDashboard);
    // console.log('localStore localDashboard: ', localDashboard);
  }, [localDashboard]);

  const addToUserPath = (element) => {
    setUserPath((userPath) => [...userPath, element]);
  };

  const popOffUserPath = () => {
    const poppedElement = userPath[userPath.length - 1];
    setUserPath(userPath.slice(0, userPath.length - 1));
    return poppedElement;
  };

  const fetchUser = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
        withCredentials: true
      });
      // console.log('Authorized User: ', data);
      setUser(data);
      return data;
    } catch (err) {
      // if error getting user return null
      console.log(err);
      setUser(null);
    }
  };

  // Context value object
  const contextValues = {
    isLoading,
    setLoading,
    dashboardType,
    setDashboardType,
    currentStep,
    setCurrentStep,
    userPath,
    addToUserPath,
    popOffUserPath,
    setUserPath,
    localDashboard,
    setLocalDashboard,
    localChecklist,
    setLocalChecklist,
    fetchUser,
    user
  };

  return <SessionContext.Provider value={contextValues}>{children}</SessionContext.Provider>;
};

export const SessionContext = createContext();
