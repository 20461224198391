import React from 'react';
import PostSubheader from './PostSubheader';
import PostTextbox from './PostTextbox';
import PostTag from './PostTag';
import PostRetrievalCard from './PostRetrievalCard';
import PostTransferCard from './PostTransferCard';

const PostIVF = ({ post }) => {
  return (
    <div className="mt-8">
      {post.methodData?.triedPregnant?.length > 0 ? (
        <>
          <PostSubheader title={'Before this, I tried'} className="mb-2" />
          <PostTextbox text={post.methodData?.triedPregnant} className="mb-5" />{' '}
        </>
      ) : null}
      <PostSubheader
        title={`${post.methodData?.retrievalCount} Egg Retrieval${
          post.methodData?.retrievalCount > 1 ? 's' : ''
        }, ${post.methodData?.transferCount} Embryo Transfer${
          post.methodData?.transferCount > 1 ? 's' : ''
        }`}
        className="mb-5"
      />
      {post.methodData?.retrievals.map((retrieval, i) => {
        return <PostRetrievalCard retrieval={retrieval} index={i} key={i} />;
      })}

      {post.methodData?.transfers.map((transfer, i) => {
        return <PostTransferCard transfer={transfer} index={i} key={i} />;
      })}
      {post.methodData?.successfulCase?.length > 0 ? (
        <>
          <PostSubheader title={'About this successful IVF'} className="mb-2" />
          <PostTextbox text={post.methodData?.successfulCase} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.pricing?.length > 0 ? (
        <>
          <PostSubheader title={'Cost information'} className="mb-2" />
          <PostTextbox text={post.methodData?.pricing} className="mb-5" />{' '}
        </>
      ) : null}

      {post.methodData?.whyWorked?.length > 0 ? (
        <>
          <PostSubheader title={'Why I think this IVF worked'} className="mb-2" />
          <PostTextbox text={post.methodData?.whyWorked} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.alternatives?.length > 0 ? (
        <>
          <PostSubheader title={'Supplements & Alternative Interventions'} className="mb-2" />
          <PostTextbox text={post.methodData?.alternatives} className="mb-5" />{' '}
        </>
      ) : null}
      {post.methodData?.advice?.length > 0 ? (
        <>
          <PostSubheader title={'My Advice'} className="mb-2" />
          <PostTextbox text={post.methodData?.advice} className="mb-5" />{' '}
        </>
      ) : null}
    </div>
  );
};

export default PostIVF;
