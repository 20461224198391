import React, { createContext, useState, useEffect } from 'react';

export const ShareProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [age, setAge] = useState(0);
  const [race, setRace] = useState('');
  const [medicationOther, setMedicatinoOther] = useState(false);
  const [method, setMethod] = useState('ivf');
  const [methodData, setMethodData] = useState({
    triedPregnant: '',
    successfulCase: '',
    medicated: 'no',
    medicationTaken: [],
    medicationOther: { selected: false, value: '' },
    whyWorked: '',
    alternatives: '',
    pricing: '',
    cycles: 0,
    donorSperm: false,
    transferCount: 1,
    transfers: [],
    retrievalCount: 1,
    retrievals: [],
    advice: '',

  });

  const [tags, setTags] = useState({
    lgbtq: { value: false, factor: 'other', name: 'LGBTQ' },
    pcos: { value: false, factor: 'female', name: 'PCOS' },
    dor: { value: false, factor: 'female', name: 'DOR' },
    poi: { value: false, factor: 'female', name: 'POI' },
    hypothalamic: { value: false, factor: 'female', name: 'Hypothalamic' },
    tubalBlockage: { value: false, factor: 'female', name: 'Tubal Blockage' },
    endometriosis: { value: false, factor: 'female', name: 'Endometriosis' },
    adenomyosis: { value: false, factor: 'female', name: 'Adenomyosis' },
    uterineFibroids: { value: false, factor: 'female', name: 'Uterine Fibroids' },
    polyps: { value: false, factor: 'female', name: 'Polyps' },
    lowSperm: { value: false, factor: 'male', name: 'Low Sperm' },
    poorMotility: { value: false, factor: 'male', name: 'Poor Motility' },
    abnormalMorphology: { value: false, factor: 'male', name: 'Abnormal Morphology' },
    varicocele: { value: false, factor: 'male', name: 'Varicocele' },
    ejaculationIssues: { value: false, factor: 'male', name: 'Ejaculation Issues' },
    hypogonadism: { value: false, factor: 'male', name: 'Hypogonadism' },
    previousMiscarriages: { value: false, factor: 'other', name: 'Previous Miscarriages' },
    repeatedTransferFailures: { value: false, factor: 'other', name: 'Repeated Transfer Failures' },
    autoImmune: { value: false, factor: 'other', name: 'Auto Immune' },
    unexplained: { value: false, factor: 'other', name: 'Unexplained' },
    notInfertile: { value: false, factor: 'other', name: 'Not Infertile' }
  });

  const resetMethodData = () => {
    setMethodData({
      triedPregnant: '',
      successfulCase: '',
      medicated: 'no',
      medicationTaken: [],
      medicationOther: { selected: false, value: '' },
      whyWorked: '',
      alternatives: '',
      pricing: '',
      cycles: '0',
      donorSperm: false,
      transferCount: 1,
      transfers: [],
      retrievalCount: 1,
      retrievals: [],
      advice: '',

    });
  };

  const resetTags = () => {
    setTags({
      lgbtq: { value: false, factor: 'other', name: 'LGBTQ' },
      pcos: { value: false, factor: 'female', name: 'PCOS' },
      dor: { value: false, factor: 'female', name: 'DOR' },
      poi: { value: false, factor: 'female', name: 'POI' },
      hypothalamic: { value: false, factor: 'female', name: 'Hypothalamic' },
      tubalBlockage: { value: false, factor: 'female', name: 'Tubal Blockage' },
      endometriosis: { value: false, factor: 'female', name: 'Endometriosis' },
      adenomyosis: { value: false, factor: 'female', name: 'Adenomyosis' },
      uterineFibroids: { value: false, factor: 'female', name: 'Uterine Fibroids' },
      polyps: { value: false, factor: 'female', name: 'Polyps' },
      lowSperm: { value: false, factor: 'male', name: 'Low Sperm' },
      poorMotility: { value: false, factor: 'male', name: 'Poor Motility' },
      abnormalMorphology: { value: false, factor: 'male', name: 'Abnormal Morphology' },
      varicocele: { value: false, factor: 'male', name: 'Varicocele' },
      ejaculationIssues: { value: false, factor: 'male', name: 'Ejaculation Issues' },
      hypogonadism: { value: false, factor: 'male', name: 'Hypogonadism' },
      previousMiscarriages: { value: false, factor: 'other', name: 'Previous Miscarriages' },
      repeatedTransferFailures: {
        value: false,
        factor: 'other',
        name: 'Repeated Transfer Failures'
      },
      autoImmune: { value: false, factor: 'other', name: 'Auto Immune' },
      unexplained: { value: false, factor: 'other', name: 'Unexplained' },
      notInfertile: { value: false, factor: 'other', name: 'Not Infertile' }
    });
  };

  const contextValues = {
    method,
    setMethod,
    methodData,
    setMethodData,
    resetMethodData,
    age,
    setAge,
    race,
    setRace,
    title,
    setTitle,
    tags,
    setTags,
    resetTags
  };

  return <ShareContext.Provider value={contextValues}>{children}</ShareContext.Provider>;
};

export const ShareContext = createContext();
