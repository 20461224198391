import React from 'react';

const PostTextbox = ({ text, className }) => {
  return (
    <div className={`text-[12px] font-serif leading-[16px] text-black ${className}`}>
      {text}
    </div>
  );
};

export default PostTextbox;
